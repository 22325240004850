import "./LinkButton.scss";
import {Link} from "react-router-dom";

interface LinkButtonProps {
	text: string,
	linkTo: string,
	type: "primary" | "success" | "warning" | "danger",
	openInNewTab?: boolean,
	width?: string,
	height?: string,
	outlined?: boolean,
	additionalClassList?: string[],
}

export default function LinkButton(props: LinkButtonProps) {
	let buttonClassList = ['button', `is-${props.type}`];
	if (props.outlined) buttonClassList.push('is-outlined');
	if (props.additionalClassList) buttonClassList.push(...props.additionalClassList);

	return (
		<Link to={props.linkTo}
					target={props.openInNewTab ? "_blank" : ""}
					className={buttonClassList.join(' ')}
					style={{width: props.width, height: props.height}}>
			{props.text}
		</Link>
	)
}
