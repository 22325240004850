import Icon from "../Icon/Icon";

export default function LoadingData() {
	return (
		<div className={"card mt-4"} style={{width: "100%"}}>
			<div className={"card-content"}>
				<div className={"content is-flex is-justify-content-center"}>
					<p style={{textAlign: "center", fontSize: "1.3rem"}}>
						Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"}/>
					</p>
				</div>
			</div>
		</div>
	)
}
