import "./MessageBox.scss";
import {CSSProperties, ReactNode} from "react";

interface MessageBoxProps {
	title: string
	type: 'primary' | 'success' | 'warning' | 'danger'
	blackBodyText?: boolean
	className?: string
	style?: CSSProperties
	children: ReactNode
}

export default function MessageBox(props: MessageBoxProps) {
	let additionalClasses = props.className || "";
	return (
		<article className={`message is-${props.type} ${additionalClasses}`} style={props.style}>
			<div className={`message-body ${props.blackBodyText && "has-text-black"}`}>
				{props.children}
			</div>
		</article>
	)
}
