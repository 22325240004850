// NOTE: We are storing tokens in localStorage because we are using rotating refresh tokens which reduces the impact
// of any reflected XSS attacks.
// https://auth0.com/blog/refresh-tokens-what-are-they-and-when-to-use-them/#You-Can-Store-Refresh-Token-In-Local-Storage

import axios, {AxiosError} from "axios";

const accessTokenIdentifier = 'ab_act';
const refreshTokenIdentifier = 'ab_rft';

/**
 * Stores jwt access token in localStorage
 * @param token: access token
 */
export function storeAccessToken(token: string) {
	localStorage.setItem(accessTokenIdentifier, token);
}

/**
 * Stores jwt refresh token in localStorage
 * @param token: refresh token
 */
export function storeRefreshToken(token: string) {
	localStorage.setItem(refreshTokenIdentifier, token);
}

/**
 * Removes jwt access token from localStorage.
 */
export function removeAccessToken() {
	localStorage.removeItem(accessTokenIdentifier);
}

/**
 * Removes jwt refresh token from localStorage.
 */
export function removeRefreshToken() {
	localStorage.removeItem(refreshTokenIdentifier);
}

/**
 * Fetches and returns acess token from browser storage. Returns `null` if not found.
 */
export function getAccessToken() {
	return localStorage.getItem(accessTokenIdentifier);
}

/**
 * Fetches and returns refresh token from browser storage. Returns `null` if not found.
 */
export function getRefreshToken() {
	return localStorage.getItem(refreshTokenIdentifier);
}

/**
 * Renews access token using refresh token. Since we have rotating refresh tokens enabled, server will also provide a
 * new refresh token for future use. Returns true if renewal was successful.
 * @param refreshToken - jwt refresh token
 * @returns boolean
 */
export async function renewAccessToken(refreshToken: string): Promise<boolean> {
	try {
		let response = await axios({
			method: "post",
			url: process.env.REACT_APP_DRF_DOMAIN + "/api/frontend/token/refresh/",
			data: {
				'refresh': refreshToken
			},
			responseType: 'json'
		});

		// axios treats any status code outside 2xx as error. So no need to check status code over here
		// store new refresh and access token
		let accessToken: string = response.data['access'];
		refreshToken = response.data['refresh'];
		localStorage.setItem(accessTokenIdentifier, accessToken);
		localStorage.setItem(refreshTokenIdentifier, refreshToken);
		return true

	} catch (err) {
		const axiosError = err as AxiosError;
		if (axiosError.response?.status === 401) {
			// Refresh token expired. Need to delete existing tokens so that user is redirected to login.
			removeAccessToken();
			removeRefreshToken();
			return false
		} else {
			console.error("token renewal failed with status code " + axiosError.response?.status);
			return false
		}
	}
}
