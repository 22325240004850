import abunLogo from "../../assets/images/branding/abun_blue_text_logo.png";
import {Link} from "react-router-dom";
import {pageURL} from "../routes";
import React from "react";

export default function MaxWebsites() {
	return (
		<div className="container is-flex is-flex-direction-column is-align-items-center">
			<img src={abunLogo} className="connectwebsite-logo" alt="Abun Logo"/>
			<h2 className={"is-size-3 has-text-centered has-text-weight-bold"}>You Can't Add Any More Websites :(</h2>
			<p className={"has-text-centered"}>
				You have reached the max number of websites that you can connect on your current plan.<br/>
				Kindly upgrade to a higher plan to connect more websites.
			</p>
			<Link className={"button is-primary mt-6"}
						to={pageURL['manageSubscription']}>
				Upgrade Plan 🔥
			</Link>
			<Link className={"has-text-grey-darker mt-4 is-underlined"}
						to={pageURL['keywordResearch']}>
				Return to Keyword Research
			</Link>
		</div>
	)
}
