import loadingIcon from "../../assets/images/loadingIcon.webp"

interface ConnectWebsiteLoadingProps {
	text: string
}

export default function ConnectWebsiteLoading(props: ConnectWebsiteLoadingProps) {
	return (
		<>
			<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
				<img className={"image"} src={loadingIcon} alt="loading" />
			</div>
			<p className={"is-size-4"}>{props.text}</p>
		</>
	)
}
