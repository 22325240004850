import { useRef } from 'react';
import audioFile from '../../assets/images/branding/abun_audio.mpeg'; // Import the audio file
import logo from '../../assets/images/branding/abun_black_text_logo.png'; // Import the Main Logo
import speaker from '../../assets/images/branding/Speaker.png'; // Import Speaker Icon
import './SuccessNavbar.scss';


//For Playing Audio
const SuccessNavbar = () => {
		const audioRef = useRef<HTMLAudioElement>(null);
	  
		const handleSpeakerClick = () => {
		  if (audioRef.current) {
			audioRef.current.play();
		  }
		};

  return (
    <nav className='main-nav'>
      <div className={`nav-container`}>
        <div className='logo'>
		<a href="https://abun.com/" style={{ cursor: 'pointer' }}><img src={logo} alt="Abun Logo" width="140px" /></a>
          <img src={speaker} alt="Speaker" width="auto" onClick={handleSpeakerClick} style={{ cursor: 'pointer' }} />
        </div>
        <audio ref={audioRef} src={audioFile}></audio>
      </div>
    </nav>
  );
}

export default SuccessNavbar;