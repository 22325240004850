import { Language } from "../../pages/Settings/ArticleDetails";

const allSupportedLanguagesOptions: Language[] = [
   {
      value: "American English (en-us)",
      label: "English (US)"
   },
   {
      value: "British English (en-gb)",
      label: "English (UK)"
   },
   {
      value: "Australian English (en-au)",
      label: "English (AUS)"
   },
   {
      value: "chinese",
      label: "Chinese"
   },
   {
      value: "spanish",
      label: "Spanish"
   },
   {
      value: "french",
      label: "French"
   },
   {
      value: "german",
      label: "German"
   },
   {
      value: "italian",
      label: "Italian"
   },
   {
      value: "portuguese",
      label: "Portuguese"
   },
   {
      value: "russian",
      label: "Russian"
   },
   {
      value: "japanese",
      label: "Japanese"
   },
   {
      value: "korean",
      label: "Korean"
   },
   {
      value: "arabic",
      label: "Arabic"
   },
   {
      value: "hindi",
      label: "Hindi"
   },
   {
      value: "albanian",
      label: "Albanian",
   },
   {
      value: "armenian",
      label: "Armenian",
   },
   {
      value: "azerbaijani",
      label: "Azerbaijani",
   },
   {
      value: "basque",
      label: "Basque",
   },
   {
      value: "belarusian",
      label: "Belarusian",
   },
   {
      value: "bengali",
      label: "Bengali",
   },
   {
      value: "bhojpuri",
      label: "Bhojpuri",
   },
   {
      value: "bosnian",
      label: "Bosnian",
   },
   {
      value: "bulgarian",
      label: "Bulgarian",
   },
   {
      value: "catalan",
      label: "Catalan",
   },
   {
      value: "croatian",
      label: "Croatian",
   },
   {
      value: "czech",
      label: "Czech",
   },
   {
      value: "danish",
      label: "Danish",
   },
   {
      value: "dogri",
      label: "Dogri",
   },
   {
      value: "dutch",
      label: "Dutch",
   },
   {
      value: "estonian",
      label: "Estonian",
   },
   {
      value: "finnish",
      label: "Finnish",
   },
   {
      value: "galician",
      label: "Galician",
   },
   {
      value: "georgian",
      label: "Georgian",
   },
   {
      value: "greek",
      label: "Greek",
   },
   {
      value: "gujarati",
      label: "Gujarati",
   },
   {
      value: "hungarian",
      label: "Hungarian",
   },
   {
      value: "indonesian",
      label: "Indonesian",
   },
   {
      value: "irish",
      label: "Irish",
   },
   {
      value: "javanese",
      label: "Javanese",
   },
   {
      value: "kannada",
      label: "Kannada",
   },
   {
      value: "kazakh",
      label: "Kazakh",
   },
   {
      value: "konkani",
      label: "Konkani",
   },
   {
      value: "kyrgyz",
      label: "Kyrgyz",
   },
   {
      value: "latvian",
      label: "Latvian",
   },
   {
      value: "lithuanian",
      label: "Lithuanian",
   },
   {
      value: "macedonian",
      label: "Macedonian",
   },
   {
      value: "maithili",
      label: "Maithili",
   },
   {
      value: "malay",
      label: "Malay",
   },
   {
      value: "maltese",
      label: "Maltese",
   },
   {
      value: "marathi",
      label: "Marathi",
   },
   {
      value: "mongolian",
      label: "Mongolian",
   },
   {
      value: "nepali",
      label: "Nepali",
   },
   {
      value: "norwegian",
      label: "Norwegian",
   },
   {
      value: "pashto",
      label: "Pashto",
   },
   {
      value: "polish",
      label: "Polish",
   },
   {
      value: "punjabi",
      label: "Punjabi",
   },
   {
      value: "romanian",
      label: "Romanian",
   },
   {
      value: "sanskrit",
      label: "Sanskrit",
   },
   {
      value: "serbian",
      label: "Serbian",
   },
   {
      value: "sindhi",
      label: "Sindhi",
   },
   {
      value: "slovak",
      label: "Slovak",
   },
   {
      value: "slovenian",
      label: "Slovenian",
   },
   {
      value: "ukrainian",
      label: "Ukrainian",
   },
   {
      value: "urdu",
      label: "Urdu",
   },
   {
      value: "uzbek",
      label: "Uzbek",
   },
   {
      value: "vietnamese",
      label: "Vietnamese",
   },
   {
      value: "welsh",
      label: "Welsh",
   },
   {
      value: "swedish",
      label: "Swedish",
   },
];

export default allSupportedLanguagesOptions;
