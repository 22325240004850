import "./CompetitorResearchKeywords.scss";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCompetitorResearchKeywordsData, markKeywordsAsSelected, removeKeywordsFromSelected } from "../../utils/api";
import React, { useEffect, useRef, useState } from "react";
import LoadingData from "../../components/LoadingData/LoadingData";
import LoadingError from "../../components/LoadingError/LoadingError";
import Card from "../../components/Card/Card";
import AbunTable, { IndeterminateCheckbox } from "../../components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import AbunButton from "../../components/AbunButton/AbunButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import AbunModal from "../../components/AbunModal/AbunModal";
import { Player } from "@lottiefiles/react-lottie-player";

interface KeywordData {
	md5_hash: string
	keyword: string
	serp_position: number | null
	volume: number
	cpc_value: number
	paid_difficulty: number
	selected: boolean
}


export default function CompetitorResearchKeywords() {
	// -------------------------- NON STATE CONSTANTS --------------------------
	const pageSizes = [30, 50, 100];

	// ------------------- REACT ROUTER -------------------
	const { compDomain } = useParams();

	// ------------------- STATES -------------------
	const [keywords, setKeywords] = useState<Array<KeywordData>>([]);
	const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();

	// ------------------- REFS -------------------
	const failAlertRef = useRef<any>(null);

	// ------------------- QUERIES -------------------
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: ['getCompetitorResearchKeywordsData'],
		queryFn: () => getCompetitorResearchKeywordsData({ competitor_domain: compDomain! }),
		cacheTime: 0,
		refetchOnWindowFocus: false
	});

	// ------------------- MUTATIONS -------------------
	const addToSelected = useMutation({
		mutationKey: ['markKeywordsAsSelected'],
		mutationFn: markKeywordsAsSelected,
		cacheTime: 0,
		onSuccess: () => {
			failAlertRef.current?.close();
			refetch().then();
		},
		onError: (error) => {
			console.error(error);
			failAlertRef.current?.show("Oops! Something went wrong. Please try again in some time.")
		}
	});

	const removeFromSelected = useMutation({
		mutationKey: ['removeKeywordsFromSelected'],
		mutationFn: removeKeywordsFromSelected,
		cacheTime: 0,
		onSuccess: () => {
			failAlertRef.current?.close();
			refetch().then();
		},
		onError: (error) => {
			console.error(error);
			failAlertRef.current?.show("Oops! Something went wrong. Please try again in some time.")
		}
	})

	// ------------------- EFFECTS -------------------
	useEffect(() => {
		if (data) {
			let results: Array<KeywordData> = data['data']['keywords'];
			setKeywords(results);
		}
	}, [data]);

	// ------------------- TABLE CODE -------------------
	const columnHelper = createColumnHelper<KeywordData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row) => row.md5_hash, {
			id: 'checkbox',
			header: ({ table }) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({ row }) => (
				<IndeterminateCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
					}}
					name={"keywordSelection"}
					value={row.original.md5_hash}
				/>
			),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row) => row.keyword, {
			id: 'keyword',
			header: "Keyword",
			cell: info => info.getValue().toString(),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row) => row.serp_position, {
			id: 'serpPosition',
			header: () => (<div style={{ textAlign: "center" }}>SERP Position</div>),
			cell: info => {
				if (info.getValue() === null) {
					return "---";
				} else {
					return info.getValue().toString();
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		columnHelper.accessor((row) => row.volume, {
			id: 'volume',
			header: () => (<div style={{ textAlign: "center" }}>Volume</div>),
			cell: info => info.getValue().toString(),
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		columnHelper.accessor((row) => row.paid_difficulty, {
			id: 'difficultyScore',
			header: () => (<div style={{ textAlign: "center" }}>Competition</div>),
			cell: props => {
				let difficulty = 'LOW'
				let btn_color = 'success'

				if (props.row.original.paid_difficulty < 0.1) {
					difficulty = 'LOW'
					btn_color = 'success'
				} else if (props.row.original.paid_difficulty > 0.1 && props.row.original.paid_difficulty < 0.2) {
					difficulty = 'MEDIUM'
					btn_color = 'info'
				}
				else if (props.row.original.paid_difficulty > 0.2 && props.row.original.paid_difficulty < 0.5) {
					difficulty = 'HIGH'
					btn_color = 'warning'
				}
				else if (props.row.original.paid_difficulty > 0.5) {
					difficulty = 'EXTREME'
					btn_color = 'danger'
				}
				return <button className={`button is-small more-rounded-borders ${btn_color !== 'info' ? `is-${btn_color}` : ''} custom-btn`} style={btn_color == 'info' ? { backgroundColor: 'yellow', color: 'black', cursor: "default" } : { cursor: "default" }} disabled>{difficulty}</button>
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		columnHelper.accessor((row) => row.cpc_value, {
			id: 'cpc',
			header: () => (<div style={{ textAlign: "center" }}>CPC (USD)</div>),
			cell: props => {
				return <span >${props.row.original.cpc_value}</span>
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		columnHelper.display({
			id: 'selectUnselectKeyword',
			header: () => (<div style={{ textAlign: "center" }}>Select Keywords</div>),
			cell: props => {
				if (props.row.original.selected) {
					return <AbunButton type={"danger"}
						className={"is-small is-outlined more-rounded-borders has-text-weight-bold"}
						style={{ width: "120px" }}
						disabled={addToSelected.isLoading}
						clickHandler={() => {
							removeFromSelected.mutate({ keyword_hash: props.row.original.md5_hash });
						}}>
						Remove
					</AbunButton>
				} else {
					return <AbunButton type={"success"}
						className={"is-small more-rounded-borders has-text-weight-bold"}
						style={{ width: "120px" }}
						disabled={addToSelected.isLoading}
						clickHandler={() => {
							addToSelected.mutate({ keyword_hash_list: [props.row.original.md5_hash] });
						}}>
						Add Keyword
					</AbunButton>
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
	]

	// ------------------- FUNCTIONS -------------------
	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	// ------------------- MAIN RENDER CODE -------------------
	if (isFetching) {
		return <LoadingData />

	} else if (error) {
		return <LoadingError />

	} else {
		return (
			<>
				<Card className={"comp-research-kw-card"}>
					<AbunTable tableContentName={"Keywords"}
						tableData={keywords}
						tableName={"Select Keywords from " + compDomain}
						columnDefs={columnDefs}
						pageSizes={pageSizes}
						initialPageSize={pageSizes[2]}
						noDataText={"No Keywords Available"}
						rowCheckbox={true}
						id="CompetitorResearchKeywordsTable"
						selectedRowsSetter={selectedRowsSetter}
						searchboxPlaceholderText={"Search Keywords..."}
						applyAction={(action) => {
							if (action === "Add Keywords") {
								if (selectedRows) {
									let keywordHashArray: Array<string> = selectedRows.rows.map(row => {
										return (row.original as KeywordData).md5_hash
									});
									addToSelected.mutate({ keyword_hash_list: keywordHashArray });
								}
							}
						}}
						bulkActions={!selectedRows?.rows.length ? [] : [
							{
								text: "Add Keywords",
								isDisabled: !(selectedRows && selectedRows.rows.length > 0),
							},
						]} />
				</Card>
				<ErrorAlert ref={failAlertRef} />
			</>
		)
	}
}
