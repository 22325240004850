import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import ReactGA from "react-ga4";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import loadingIcon from "../../assets/images/loadingIcon.webp";
import AbunModal from '../../components/AbunModal/AbunModal';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import SuccessNavbar from "../../components/SuccessNavbar/SuccessNavbar";
import { APIError, checkoutSuccessQuery, generateV2ArticleMutation, makeApiRequest } from "../../utils/api";
import { pageURL } from "../routes";

import "./CheckoutSuccess.scss";

interface CheckoutSuccessData {
	amount: number
	charge_id: string
	currency: string
	customer_id: string
	plan_name: string
}

export default function CheckoutSuccess() {
	const navigate = useNavigate();

	// --------------------- QUERY PARAMETERS ---------------------
	let [searchParams] = useSearchParams();
	let checkoutSessionID = searchParams.get("session_id");
	const [Error, setError] = useState('');
	const [modalText, setModalText] = useState("");
	const [requestModalActive, setRequestModalActive] = useState(false);
	const failAlertRef = useRef<any>(null);
	const [isVerified, setVerified] = useState(false);
	const [articleUID, setArticleUid] = useState('');

	// --------------------- QUERIES ---------------------
	const { isLoading, error, data } = useQuery(checkoutSuccessQuery(checkoutSessionID));

	useEffect(() => {
		document.title = "Checkout Successful | Abun";
		if (!isLoading && (data as any).data) {
			ReactGA.gtag('event', 'conversion', {
				'send_to': 'AW-11453495091/U8jKCJC35IkZELPuudUq',
				'value': ((data as any).data as CheckoutSuccessData).amount,
				'currency': ((data as any).data as CheckoutSuccessData).currency
			}
			);

			// checkout tracking Partnero script
			const checkoutTrackingScript = document.createElement('script');
			checkoutTrackingScript.innerHTML = `
			  po('transactions', 'create', {
				 data: {
					key: '${((data as any).data as CheckoutSuccessData).charge_id}',
					amount: ${((data as any).data as CheckoutSuccessData).amount},
					amount_units: '${((data as any).data as CheckoutSuccessData).currency}',
					customer: {
					  key: '${((data as any).data as CheckoutSuccessData).customer_id}'
					}
				 },
				 options: {
					create_customer: true
				 }
			  });
			`;
			document.head.appendChild(checkoutTrackingScript);
			// Call generateArticle function after the checkout success
			if (keyword) {
				generateNewArticle();  // Call generateNewArticle function here
				localStorage.removeItem('keyword');  // Remove keyword from localStorage after generating the article
			}

			return () => {
				// Remove the tracking script when the component unmounts
				document.head.removeChild(checkoutTrackingScript);
			};
		}
	}, [data, isLoading]);


	// --- Generate Article --- //
	// Retrieve stored data from local storage
	const keyword = localStorage.getItem('keyword');
	const title = localStorage.getItem('selectedTitle');
	const creative_titles = JSON.parse(localStorage.getItem('creativeTitles') || '[]');
	const serp_titles = JSON.parse(localStorage.getItem('serpTitles') || '[]');

	const generateNewArticle = async () => {
		if (!title || !keyword) {
			return;
		}
		setError('');
		try {
			const response = await makeApiRequest(
				'/api/frontend/new-article-data/',
				'post',
				{
					keyword: keyword,
					title: title,
					serp_titles: serp_titles,
					creative_titles: creative_titles,
				}
			);

			const data = response.data;
			if (data.success) {
				setArticleUid(data.article_uid);
				generateArticleHandler(data.article_uid);
			} else {
				setError(data.error || 'Failed to generate article.');
			}
		} catch (err) {
			if (err instanceof APIError) {
				if (err.statusCode === 401) {
					setError('Unauthorized. Please log in again.');
				} else {
					setError('An error occurred while generating the article: ' + (err.responseData.message || 'Unknown error'));
				}
			} else {
				setError('An unexpected error occurred: ' + ('Unknown error'));
			}
		}
	}
	//Logic to Generate Article
	const generateArticle = useMutation(generateV2ArticleMutation);
	function generateArticleHandler(articleUID: string) {
		setModalText("Processing request. Please wait...");
		setRequestModalActive(true);
		generateArticle.mutate(articleUID, {
			onSuccess: (data) => {
				setRequestModalActive(false);
				let responseData = (data as any)["data"];

				if (responseData["status"] === "sent_for_processing") {
					navigate(`/articles/edit/${articleUID}/`);
				} else if (responseData["status"] === "rejected") {
					if (responseData["reason"] === "max_limit_reached") {
						failAlertRef.current?.show("Article generation request failed. " +
							"You have reached your max article generation limit for the month.");
					} else {
						failAlertRef.current?.show(`Article generation request failed. Error ID: ${responseData["reason"]}`);
					}
				} else {
					failAlertRef.current?.show(
						`Article generation request returned unknown status ${responseData["status"]}. Please contact us if there's any issue.`
					);
				}
			},
			onError: (error: Error) => {
				console.error(error);
				setRequestModalActive(false);
				failAlertRef.current?.show(`Article generation request failed. Please try again later`)
				setTimeout(() => {
					failAlertRef.current?.close();
				}, 5000);
			}
		});
	}


	if (isLoading) {
		return (
			<div className={"checkout-success-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
								<img className={"image"} src={loadingIcon} alt="loading" />
							</div>
							<h2 className={"is-size-4 has-text-centered"}>Fetching Payment Details. Please Wait...</h2>
						</div>
					</div>
				</div>
			</div>
		)

	} else if (error) {
		return (
			<div className={"checkout-success-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<p className={"has-text-centered"}>Oops! Something went wrong :(</p>
							<p className={"has-text-danger has-text-centered mt-4"}>{error.toString()}</p>
						</div>
					</div>
				</div>
			</div>
		)

	} else {
		return (
			<div className={"success-container"}>
					<SuccessNavbar/>
				<div className={"card card-contain"}>
					<div className={"check-icon"}>✅</div>
					<h2 className={"has-text-centered has-text-weight-bold"}>Payment Successful!</h2>
					<p className={"has-text-centered mt-3"}>
							You are now subscribed to Monthly <b>{(data as any)['data']['plan_name']}</b> plan.
					</p>
					<div className={"btns"}>
					<Link to={pageURL['createArticle']} className={"button is-primary mt-5"}>Create Article</Link>
					</div>
				</div>
				{/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
				<AbunModal active={requestModalActive}
					headerText={""}
					closeable={false}
					hideModal={() => {
						setRequestModalActive(false)
					}}>
					<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
						<img className={"image"} src={loadingIcon} alt="loading" />
					</div>
					<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
				</AbunModal>

				<ErrorAlert ref={failAlertRef} />
			</div>
		)
	}
}
