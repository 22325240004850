import {ReactNode} from "react";

interface CardProps {
	children: ReactNode
	className?: string
	cardHeaderTitle?: string
}

export default function Card(props: CardProps) {
	return (
		<div className={`card ${props.className ? props.className : ""}`}>
			{props.cardHeaderTitle && <div className={"card-header"}>
				<div className={"card-header-title"}>
					{props.cardHeaderTitle}
				</div>
			</div>}
			<div className={"card-content"}>
				<div className={"content"}>
					{props.children}
				</div>
			</div>
		</div>
	)
}
