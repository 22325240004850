import './Tutorials.scss';
import { useState } from 'react';
import tutorial1 from '../../assets/images/tutorials/tut-1.jpeg';

import AbunModal from '../../components/AbunModal/AbunModal';

export default function Tutorials() {

    // -------------------------------------- STATES --------------------------------------
    const [videoModalActive, setVideoModalActive] = useState(false);
    const [activeVideoURL, setActiveVideoURL] = useState("");

    // -------------------------------------- CONSTANT --------------------------------------
    const tutorial_videos = [
        {
            id: 1,
            title: "How to Create SEO-Optimized AI Articles using Abun",
            url: "https://www.youtube-nocookie.com/embed/aid0_5XlecA?si=4Dm3IcgMJLhp8SoQ",
            thumbnail: tutorial1
        },
    ]
    return (
        <>
            <AbunModal active={videoModalActive}
				headerText={""}
				closeable={true}
				wideModal={true}
				hideModal={() => setVideoModalActive(false)}>
				{videoModalActive && (
					<div className={"iframe-container"}>
						<iframe
							src={activeVideoURL}
							title="YouTube video player"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							className={"mt-5"}
							style={{ borderRadius: "12px" }}
							allowFullScreen
						/>
					</div>
				)}
			</AbunModal>
            <div className="tutorial-container">
                <h2 className={"is-size-4 mb-6 has-text-weight-bold font-secondary"}>
                    Get Started with Abun
                </h2>
                {tutorial_videos.length > 0 ?
                    <div className="video-grid">
                            {
                                tutorial_videos.map((video) => (
                                    <div onClick={() => {
                                        setVideoModalActive(true);
                                        setActiveVideoURL(video.url);
                                    }} className="video-item" key={video.id}>
                                        <img
                                            alt={video.title}
                                            height={200}
                                            src={video.thumbnail}
                                            width={300}
                                        />
                                        <h3 className={"is-size-5 font-secondary"}>Tutorial: {video.title}</h3>
                                    </div>
                                ))
                            }
                    </div> :
                    <h1 className="abun-table-title w-100 has-text-centered is-size-3">
                        No tutorials available right now.
                    </h1>
                }
            </div>
        </>
    )
}