import {useRef, useState, useEffect} from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import {useMutation} from "@tanstack/react-query";
import {domainCheckMutation, domainSuggestion} from "../../utils/api";
import Input from "../../components/Input/Input";

interface WebsiteDomainProps {
	nextStage: () => void
	getWebsiteData: () => Object
	updateWebsiteData: (key: any, value: any) => void
	getWebsiteTempData: () => Object
	updateWebsiteTempData: (key: any, value: any) => void

}

interface DomainCheckResults {
	domain: string
	protocol: string
	already_connected: boolean
	blocked_domain: boolean
	valid: boolean
}

export default function WebsiteDomain(props: WebsiteDomainProps) {
	// -------------------- STATES --------------------
	const [domainInput, setDomainInput] = useState(props.getWebsiteData()['domain'] ? props.getWebsiteData()['domain'] : '');
	const [domainSuggestions, setDomainSuggestions] = useState<any[]>([]);

	// -------------------- REFS --------------------
	const errorAlertRef = useRef<any>(null);

	// -------------------- MUTATIONS --------------------
	const domainCheck = useMutation(domainCheckMutation);

	// useEffect(() => {
	// 	if (data) {
	// 		let domainCheckResult: DomainCheckResults = (data as any)['data'];
	// 		if (domainCheckResult.already_connected) {
	// 			errorAlertRef.current.show("This website is already connected. " +
	// 				"Please login using the correct account or switch your current website from the menu.");
	//
	// 		} else if (!domainCheckResult.valid) {
	// 			errorAlertRef.current.show(`Please enter a valid website domain. Example - https://example.com or example.com`);
	//
	// 		} else {
	// 			// save domain, protocol, blog and proceed to next stage.
	// 			props.updateWebsiteData('domain', domainCheckResult.domain);
	// 			props.updateWebsiteData('protocol', domainCheckResult.protocol);
	// 			props.nextStage();
	// 		}
	// 	}
	// }, [data]);

	/**
	 * Checks domain validity and saves data.
	 */
	async function buttonClickHandler() {
		errorAlertRef.current?.close();

		if(!domainInput.trim()) {
			errorAlertRef.current.show(`Please enter a valid website domain. Example - https://example.com or example.com`);
			return; // no domain name entered
		}
		if(domainInput.includes("@")) {
			return; // stop email input as domain name
		}
		if (props.getWebsiteData()['domain'] !== domainInput) {
			domainCheck.mutate(domainInput, {
				onSuccess: (data) => {
					let domainCheckResult: DomainCheckResults = (data as any)['data'];
					if (domainCheckResult.already_connected) {
						errorAlertRef.current.show("This website is already connected. " +
							"Login using the correct account or switch your current website from the menu. " +
							"Please reach out to us using live chat if you are unable to resolve the issue.");

					} else if (!domainCheckResult.valid) {
						errorAlertRef.current.show(
							`Please enter a valid website domain. Example - https://example.com or example.com`
						);

					} else if (domainCheckResult.blocked_domain){
						errorAlertRef.current.show(
							`You cannot connect this website. If you feel this is incorrect, please contact us via live chat.`
						);
					} else {
						// save domain, protocol, blog and proceed to next stage.
						props.updateWebsiteData('domain', domainCheckResult.domain);
						props.updateWebsiteData('protocol', domainCheckResult.protocol);
						props.nextStage();
					}
				},
				onError: () => {
					errorAlertRef.current.show("Oops! Something went wrong. Please try again later. " +
						"If the issue persists, please contact us for further support.");
				}
			});
		}
		else {
			props.nextStage();
		}

	}

	/**
	 * Handles domain input.
	 * @param value
	 */
	async function handleDomainInput(value: string) {
		setDomainInput(value);
		// show suggestions on domain input
		if (value.length > 2) {
			// show suggestions from API
			await domainSuggestion(value).then((response) => {
				const data = response.data;
				setDomainSuggestions(data.suggestions);
			}).catch((error) => {
				console.log(error);
				setDomainSuggestions([]);
			});
		} else {
			setDomainSuggestions([]);
		}
	}

	/**
	 * Handles suggestion click.
	 * @param domain
	 */
	function HandleSuggestionClick(domain: any) {
		setDomainInput(domain.domain);
		setDomainSuggestions([]);
	}

	// hiding suggestions, on clicking outside of domain input
	const handleOutsideClick = (e: MouseEvent) => {
		const suggestionsContainer = document.querySelector('.suggestions-container');
		const inputField = document.querySelector('.connectwebsite-domain-form .field input');
		
		if (
			suggestionsContainer &&
			!suggestionsContainer.contains(e.target as Node) &&
			inputField &&
			e.target !== inputField
		) {
			setDomainSuggestions([]);
		}
	};

	useEffect(() => {
		document.addEventListener('click', handleOutsideClick);
		
		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	return (
		<>
			<p className="connectwebsite-title">What is your website?</p>
			<div className="connectwebsite-domain-form">
				<div className="field">
					<label className="label">Website Domain&nbsp;<span className="has-text-danger">*</span></label>
					<Input value={domainInput}
								 type={"text"}
								 onChange={handleDomainInput}
								 placeholder={"example.com"}/>
								 {domainInput.includes("@") && <p className={"has-text-centered mt-2"}>Please enter a valid website domain!</p>}
					{/* show suggestions on domain input */}
					<div className="suggestions-container">
						{domainSuggestions.map((domainSuggestion, index) => (
							<div key={index} className={"suggestion-item"} onClick={() => HandleSuggestionClick(domainSuggestion)}>
								<div className={"suggestion-info"}>
									<img src={domainSuggestion.logo} alt={domainSuggestion.name} className={"suggestion-logo"}/>
									<span title={domainSuggestion.name}>{domainSuggestion.name}</span>
								</div>
								<span className={"suggestion-domain"}>{domainSuggestion.domain}</span>
							</div>
						))}
					</div>
				</div>
				<GenericButton text={"Add Website Domain"}
											 type={"success"}
											 disable={domainInput.includes("@")}
											 clickHandler={buttonClickHandler}
											 additionalClassList={['is-block', 'ml-auto', 'mr-auto', 'mt-6']}/>
			</div>
			{/* shows error notification if error message is set to non-empty string using its show() method */}
			<ErrorAlert ref={errorAlertRef}/>
		</>
	)
}
