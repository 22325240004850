export default function LoadingError() {
	return (
		<div className={"card mt-4 article-editor-page-card"}>
			<div className={"card-content"}>
				<div className={"content is-flex is-justify-content-center"}>
					<p style={{textAlign: "center", fontSize: "1.3rem"}}>
						Oops! Something went wrong :( Please try again later.
					</p>
				</div>
			</div>
		</div>
	)
}
