import React, { useEffect, useState } from 'react';
import './CreateTitles.scss';
import GenericButton from '../../components/GenericButton/GenericButton';
import Icon from '../../components/Icon/Icon';
import { redirect } from 'react-router-dom';
import { pageURL } from '../routes';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const CreateTitles = () => {
  const [activeTab, setActiveTab] = useState('creative'); // Track active tab
  const [previousKeyword, setPreviousKeyword] = useState(''); // Store the previous keyword to track changes
  const [keyword, setKeyword] = useState(''); // Track keyword input
  const [loading, setLoading] = useState(false); // Track loading state
  const [creativeTitles, setCreativeTitles] = useState([]); // Store fetched creative titles
  const [serpTitles, setSerpTitles] = useState([]); // Store fetched SERP-based titles
  const [error, setError] = useState(''); // Track errors
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=6Lcy5WoqAAAAACzzo47l9_cZpm9Zo5-6POEFkkbP`;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Function to check env
  const isENVMode = () => {
    return process.env.REACT_APP_DRF_DOMAIN === 'https://api.abun.com';
  };

  // Function to fetch Creative Titles
  const fetchCreativeTitles = async () => {
    if (!keyword) {
      setError('Please enter a keyword.');
      return;
    }

    // Check if keyword has changed
    if (keyword === previousKeyword && creativeTitles.length > 0) {
      setActiveTab('creative'); // No need to fetch again, just switch to the creative tab
      return;
    }

    setLoading(true); // Set loading to true when starting the fetch
    setError(''); // Reset error
    setCreativeTitles([]); // Clear previous creative titles
    setSerpTitles([]);

    try {

       // Get the reCAPTCHA token
    const token = await window.grecaptcha.execute('6Lcy5WoqAAAAACzzo47l9_cZpm9Zo5-6POEFkkbP', { action: 'submit' });

      const response = await fetch(process.env.REACT_APP_DRF_DOMAIN+'/api/frontend/get-title-data/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          keyword: keyword,
          type: 'creative', // Fetch only creative titles
          'g-recaptcha-response': token, // Include reCAPTCHA token
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setCreativeTitles(data.titles || []);
        setActiveTab('creative'); // Set creative tab as active
        setPreviousKeyword(keyword); // Update previous keyword
      } else {
        setError('Failed to fetch creative titles.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false); // Set loading to false after fetching is done
    }
  };

  // Function to handle Serp Titles
  const fetchSerpTitles = async () => {
    if (!keyword) {
      return;
    }
    // Check if keyword has changed
    if (serpTitles.length > 0) {
      setActiveTab('serp'); // No need to fetch again, just switch to the serp tab
      return;
    }
    setLoading(true); // Set loading to true when starting the fetch
    setError(''); // Reset error

    try {
        // Get the reCAPTCHA token
    const token = await grecaptcha.execute('6Lcy5WoqAAAAACzzo47l9_cZpm9Zo5-6POEFkkbP', { action: 'submit' });
      // API request with the keyword and type (activeTab)
      const response = await fetch(process.env.REACT_APP_DRF_DOMAIN + '/api/frontend/get-title-data/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          keyword: keyword, // Send the keyword
          type: 'serp',  // Send the activeTab as 'type' ('creative' or 'serp')
          'g-recaptcha-response': token, // Include reCAPTCHA token
        }),
      });

      const data = await response.json();

      if (response.ok) {
        // Update the state with fetched titles
        setSerpTitles(data.titles || []);
        setActiveTab('serp'); // Set serp tab as active
      } else {
        setError(data.error || 'Failed to fetch titles.');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false); // Set loading to false after fetching is done
    }
  };
  const handleTitleSelection = (title) => {
    // Store selected title in local storage along with other data
    localStorage.setItem('selectedTitle', title);
    localStorage.setItem('keyword', keyword);
    localStorage.setItem('creativeTitles', JSON.stringify(creativeTitles));
    localStorage.setItem('serpTitles', JSON.stringify(serpTitles));

    return navigate(pageURL['newSignUp']);
  };


  return (
    <div>
      <h2 className="generator-header">Choose any Article Title to Generate Article</h2>

       {/* Conditionally render the keyword input based on DEBUG mode */}
       {!isENVMode() && (
        <div className='input-container'>
          <input
            className="ca-input"
            type="text"
            placeholder="Enter keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            required
          />
          <button className="submit-button" type="submit" onClick={fetchCreativeTitles}>
            &#8594; 
          </button>
        </div>
      )}

      {/* Conditionally render based on loading state */}
      {loading ? (
        <h2 className="generator-header">Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} /></h2>
      ) : (
        <>
          {/* Show suggestion buttons only when not loading */}
          <div className="ca-suggestions">
            <button
              className={`ca-suggestion-button ${activeTab === 'creative' ? 'active' : ''}`}
              onClick={() => {
                if (activeTab !== 'creative') {
                  fetchCreativeTitles(); // Only fetch if switching to creative
                }
              }}
            >
              Creative Ideas
              <span
                className="circle-question"
                data-tooltip-id="style_button"
                data-tooltip-content="AI brainstormed, Creative Titles designed to help you stand out!"
              >
                ?
              </span>
            </button>
            <button
              className={`ca-suggestion-button ${activeTab === 'serp' ? 'active' : ''}`}
              onClick={() => {
                if (activeTab !== 'serp') {
                  fetchSerpTitles(); // Only fetch if switching to serp
                }
              }}
            >
              SERP Based Ideas
              <span
                className="circle-question"
                data-tooltip-id="style_button"
                data-tooltip-content="Top-ranking Titles inspired by SERP results!"
              >
                ?
              </span>
            </button>
            {/* React Tooltip Component */}
            <Tooltip id="style_button" place="bottom" />
          </div>

          <div className="generator-card">
            {/* Conditionally render titles based on active tab */}
            {(activeTab === 'creative' ? creativeTitles : serpTitles).length > 0 ? (
              <table className="article-table">
                <thead>
                  <tr>
                    <th className="article-title-header">Article Title</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(activeTab === 'creative' ? creativeTitles : serpTitles).map((title, index) => (
                    <tr key={index} style={{ cursor: 'default' }} >
                      <td className="article-title-header" onClick={() => handleTitleSelection(title)} >{title}</td>
                      <td>
                        <GenericButton
                          text={"Create Article"}
                          type={"primary"}
                          width={"100px"}
                          outlined={true}
                          additionalClassList={["is-small", "more-rounded-borders"]}
                          clickHandler={() => handleTitleSelection(title)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>No titles generated yet. Try submitting a keyword!</div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CreateTitles;
