import {useMemo} from "react";
import {useLocation} from "react-router-dom";

import defaultCompetitorLogo1 from "../assets/images/icons/defaultCompetitorLogos/default-competitor-logo1.png";
import defaultCompetitorLogo2 from "../assets/images/icons/defaultCompetitorLogos/default-competitor-logo2.png";
import defaultCompetitorLogo3 from "../assets/images/icons/defaultCompetitorLogos/default-competitor-logo3.png";
import defaultCompetitorLogo4 from "../assets/images/icons/defaultCompetitorLogos/default-competitor-logo4.png";


/* helper array to get random default logos from  */
const defaultCompetitorLogos = [defaultCompetitorLogo1, defaultCompetitorLogo2, defaultCompetitorLogo3, defaultCompetitorLogo4];

/* returns a random default logo image */
export function getDefaultCompetitorLogo() {
	return defaultCompetitorLogos[Math.floor(Math.random() * defaultCompetitorLogos.length)];
}


/**
 * Counts all characters except spaces.
 *
 * @param value - string
 */
export function getCharacterCount(value: string) {
	return value.trim().replaceAll(' ', '').length;
}

/**
 * Capitalizes first character of the text or word. ex. "hello world!" -> "Hello world!"
 *
 * @param text - word or text to capitalize.
 */
export function capitalizeFirstCharacter(text: string) {
	return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Returns total achievable traffic string (ex. "50k-100k").
 *
 * @param min_value: ex. 50000
 * @param max_value: ex. 100000
 */
export function getTotalAchievableTrafficString(min_value: number, max_value: number): string {
	let min_value_digits: number = min_value.toString().length;
	let max_value_digits: number = min_value.toString().length;
	let min_value_str: string;
	let max_value_str: string;

	// ---------- min value ----------
	if (min_value_digits > 6) {
		// million and higher
		min_value_str = (min_value / 1e6).toFixed(1) + "M";
	} else if (min_value_digits > 3) {
		// thousands
		min_value_str = (min_value / 1e3).toFixed(1) + "M";
	} else {
		// hundreds and lower
		min_value_str = min_value.toString();
	}

	// ---------- max value ----------
	if (max_value_digits > 6) {
		// million and higher
		max_value_str = (max_value / 1e6).toFixed(1) + "M";
	} else if (max_value_digits > 3) {
		// thousands
		max_value_str = (max_value / 1e3).toFixed(1) + "M";
	} else {
		// hundreds and lower
		max_value_str = max_value.toString();
	}

	if (min_value_str === max_value_str) {
		return `~${max_value_str}`;
	} else {
		return `${min_value_str} - ${max_value_str}`;
	}
}

/**
 * Checks for duplicate items in Array. Returns true if there are duplicate items.
 * Only arrays of string and number are allowed.
 */
export function hasDuplicates(arr: Array<string | number>): boolean {
	let arr2 = [...new Set(arr)];
	return arr2.length < arr.length;
}
