import "./Input.scss";

interface InputProps {
	value: string
	type: string
	placeholder?: string
	className?: string
	disabled?: boolean
	onChange: (value: string) => void
	autofocus? : boolean
}

export default function Input(props: InputProps) {
	return (
		<div className={`control ${props.className}`}>
			<input className={"input"}
						 type={props.type}
						 value={props.value}
						 autoFocus={props.autofocus ? true : false} 
						 onChange={(e) => {props.onChange(e.target.value)}}
						 placeholder={props.placeholder} disabled={props.disabled}/>
		</div>
	)
}
