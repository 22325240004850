/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";

import "./DelayedInput.scss";

export interface ellipsisLinksData {
	name: string,
	path: string
}

interface DelayedInputProps {
	initialValue: string,
	delayInMilliseconds: number,
	placeholder?: string,
	additionalClasses?: Array<string>,
	danger?: boolean,
	// Increment this value using a state in parent component to trigger input field reset.
	// Otherwise just pass 0.
	resetInput: number | undefined;
	ellipsis?: boolean;
	ellipsisLinks?: Array<ellipsisLinksData>
	onChange: (value: string) => void,
}

export default function DelayedInput(props: DelayedInputProps) {
	// States
	const [value, setValue] = useState(props.initialValue);
	const [showDropDown, setshowDropDown] = useState(false);
	const navigate = useNavigate();

	// Refs
	const inputRef = useRef<HTMLInputElement>(null);

	let classList: Array<string> = ["input"];
	if (props.additionalClasses) {
		classList.push(...props.additionalClasses);
	}

	// To prevent resetting value unintentionally
	useEffect(() => {
		setValue(props.initialValue);
	}, [props.initialValue]);

	// For the actual delay logic
	useEffect(() => {
		const timeout = setTimeout(() => {props.onChange(value)}, props.delayInMilliseconds);
		return () => clearTimeout(timeout);
	}, [value]);

	// For setting danger color outline
	useEffect(() => {
		toggleDanger(props.danger);
	}, [props.danger]);

	// For clearing the input field
	useEffect(() => {
		if (props.resetInput) {
			clearInput();
		}
	}, [props.resetInput]);

	function toggleDanger(val: boolean | undefined) {
		if (val) {
			inputRef.current?.classList.add("is-danger");
		} else {
			inputRef.current?.classList.remove("is-danger");
		}
	}

	function clearInput() {
		setValue("");
	}

	function displayEllipsisDropDown(ellipsisDropDownOptions: Array<ellipsisLinksData>){
		return (
			<>
				<div className="ellipsis-dropdown-content">
					{
						ellipsisDropDownOptions.map((option: ellipsisLinksData, index: number) => (
							<li className="ellipsis-dropdown" key={index} onClick={() => navigate(option.path)}> {option.name} </li>
						))
					}
				</div>
			</>
		)
	}

	if (props.ellipsis){

		return (
			<>
				<div onClick={() => {setshowDropDown(!showDropDown)}} className="ellipsis">&#xFE19;</div>
				{showDropDown && props.ellipsisLinks && displayEllipsisDropDown(props.ellipsisLinks)}
				<input type="text"
					   className={classList.join(' ')}
					   value={value}
					   onChange={(event) => setValue(event.target.value)}
					   placeholder={props.placeholder} ref={inputRef}/>
				
			</>
		)
	} else {
		return (
			<>
				<input type="text"
					   className={classList.join(' ')}
					   value={value}
					   onChange={(event) => setValue(event.target.value)}
					   placeholder={props.placeholder} ref={inputRef}/>
				
			</>
		)
	}
}
