import Input from "../../components/Input/Input";
import {MutableRefObject, useState, useEffect, Dispatch, SetStateAction } from "react";
import { useRouteLoaderData } from "react-router-dom";
import TextArea from "../../components/TextArea/TextArea";
import {saveWebsiteDetailsMutation} from "../../utils/api";
import {useMutation} from "@tanstack/react-query";
import { BasePageData } from "../Base/Base";

interface WebsiteDetailsProps {
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	siteTitle: string
	siteDescription: string
	siteIndustry: string
	siteICP: string
	setUnsavedChanges : Dispatch<SetStateAction<boolean>>
}

export default function WebsiteDetails(props: WebsiteDetailsProps) {
	// ---------------------------- STATES ----------------------------
	const [siteTitle, setSiteTitle] = useState(props.siteTitle);
	const [siteDescription, setSiteDescription] = useState(props.siteDescription);
	const [siteIndustry, setSiteIndustry] = useState(props.siteIndustry);
	const [siteICP, setSiteICP] = useState(props.siteICP);

	// ---------------------------- LOADER ----------------------------
	const basePageData = useRouteLoaderData("base") as BasePageData;
	const {active_website_domain} = basePageData;

	// ---------------------------- MUTATIONS ----------------------------
	const saveWebsiteDetails = useMutation(saveWebsiteDetailsMutation);

	// on tab change, check if there are unsaved changes
	useEffect(() => {
		const hasUnsavedChanges = siteTitle !== props.siteTitle ||
			siteDescription !== props.siteDescription ||
			siteIndustry !== props.siteIndustry ||
			siteICP !== props.siteICP;
		props.setUnsavedChanges(hasUnsavedChanges);
	}, [siteTitle, siteDescription, siteIndustry, siteICP, props]);

	// ===================================================================
	// ---------------------------- MAIN CODE ----------------------------
	// ===================================================================
	return (
		<div className={"card w-100"}>
			<div className={"card-content settings-card"}>
				<div className={"content"}>
					<h1 className="is-size-4 mb-3">{active_website_domain}</h1>
					<div className={"website-details-container"}>
						<label className={"label"}>
						What is your website about?(In one line)
							<Input value={siteTitle}
										 type={"text"}
										 placeholder={"Enter your website title"}
										 onChange={setSiteTitle}/>
						</label>
						<label className={"label mt-3"}>
						Tell us more about your Website/Product/Service in brief
							<TextArea value={siteDescription}
												placeholder={"Enter your website description..."}
												onChange={setSiteDescription}/>
						</label>
						<label className={"label mt-3"}>
							Industry:
							<Input value={siteIndustry}
										 type={"text"}
										 placeholder={"Enter your website industry"}
										 onChange={setSiteIndustry}/>
						</label>
						<label className={"label mt-3"}>
							Site ICP Text:
							<TextArea value={siteICP}
												placeholder={"Enter your website ICP (Ideal Customer Profile)"}
												onChange={setSiteICP}/>
						</label>

						{/* Save Changes Button */}
						<div className="save-changes-section mt-4">
							<button
								className={`button is-primary ${saveWebsiteDetails.isLoading ? 'is-loading' : ''}`}
								onClick={() => {
									props.successAlertRef.current?.close();
									props.errorAlertRef.current?.close();
									saveWebsiteDetails.mutate({
										title: siteTitle,
										desc: siteDescription,
										ind: siteIndustry,
										icp: siteICP,
									}, {
										onSuccess: () => {
											props.setUnsavedChanges(false);
											props.successAlertRef.current?.show(
												"Website details save successfully!"
											);
											setTimeout(() => {
												try {
													if (props.successAlertRef.current) {
														props.successAlertRef.current.close();
													}
												} catch (e) { }
											}, 3000);
										},
										onError: () => {
											props.errorAlertRef.current?.show(
												"Oops! Something went wrong :( Please try " +
												"again later or contact us for further support."
											);
										}
									})
								}}>
								Save Changes
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
