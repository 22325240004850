import { Link } from "react-router-dom";
import "./ErrorAlert.scss";
import { forwardRef, useImperativeHandle, useState } from "react";

type NextStep = {
   nextStepLinkText: string,
   nextStepLinkUrl: string,
}

export default forwardRef(function ErrorAlert(props, ref) {
   const [errorMessage, setErrorMessage] = useState("");
   const [nextStep, setNextStep] = useState<NextStep>();

   useImperativeHandle(
      ref,
      () => {
         return {
            show,
            close,
         };
      },
      []
   );

   // optionally accepts a link
   function show(errorMessage: string,  nextStep : NextStep) {
      setErrorMessage(errorMessage);
      nextStep && setNextStep(nextStep);
   }

   function close() {
      setErrorMessage("");
   }

   if (errorMessage) {
      return (
         <div className="notification is-warning position-bottom">
            <button className="delete" onClick={close}></button>
            {errorMessage}
            {" "}
            {nextStep?.nextStepLinkUrl && <Link to={nextStep.nextStepLinkUrl}>{nextStep.nextStepLinkText}</Link>}
         </div>
      );
   } else {
      return null;
   }
});
