import "./SuccessAlert.scss";
import {forwardRef, useImperativeHandle, useState} from "react";
import { Link } from "react-router-dom";

type NextStep = {
   nextStepLinkText: string,
   nextStepLinkUrl: string,
}

export default forwardRef(function SuccessAlert(props, ref) {
	const [message, setMessage] = useState('');
	const [nextStep, setNextStep] = useState<NextStep>();

	useImperativeHandle(ref, () => {
		return {
			show,
			close
		}
	}, []);

	// optionally accepts a link
	function show(successMessage: string, nextStep : NextStep) {
		setMessage(successMessage);
		nextStep && setNextStep(nextStep);
	}

	function close() {
		setMessage('');
	}

	if (message) {
		return (
			<div className="notification is-success position-bottom">
				<button className="delete" onClick={close}></button>
				{message}
				{" "}
            {nextStep?.nextStepLinkUrl && <Link to={nextStep.nextStepLinkUrl}>{nextStep.nextStepLinkText}</Link>}
			</div>
		)
	} else {
		return null;
	}

})
