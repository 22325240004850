import { useMutation } from "@tanstack/react-query";
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLoaderData, useLocation, useRevalidator } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import articleIntegrationIcon from "../../assets/images/article-integration-logo.png";
import loadingIcon from "../../assets/images/loadingIcon.webp";
import webflowIconPrimary from "../../assets/images/webflow-logo-primary.png";
import webflowIconSuccess from "../../assets/images/webflow-logo.png";
import wixIconPrimary from "../../assets/images/wix-logo-primary.png";
import wordpressIconPrimary from "../../assets/images/wordpress-logo-primary.png";
import wordpressIconSuccess from "../../assets/images/wordpress-logo.png";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunTable, { IndeterminateCheckbox } from "../../components/AbunTable/AbunTable";
import GenericButton from "../../components/GenericButton/GenericButton";
import Icon from "../../components/Icon/Icon";
import LinkButton from "../../components/LinkButton/LinkButton";
import {
	archiveBulkArticleMutation,
	createCustomTitleForKeywordMutation,
	generateBulkV2ArticleMutation,
	GenerateTitlesFromKeyword,
	generateV2ArticleMutation,
	getArticleProgress,
	postArticleMutation,
} from "../../utils/api";
import { CustomContextMenu } from "./KeywordResearch";

import { useNavigate } from "react-router-dom";
import AbunButton from "../../components/AbunButton/AbunButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import "../Articles/Articles.scss";
import { AllIntegrations } from "../Articles/ShowV2Articles";
import { pageURL } from "../routes";

interface ServerData {
	titles_data: Array<ArticleTitle>
	all_integrations: any
	google_search_console_integrated: boolean;
	user_verified: boolean;
	keyword: string;
	keywordHash: string;
	keywordTraffic: number;
	difficultyScore: string;
	locationIsoCode: string;
	keyword_project_id: string;
	pattern: string;
}

// type for edit btn for seo title table 
interface EditableTitle {
	title: string;
	isEditing: boolean;
}

interface ArticleTitle {
	articleUID: string
	articleTitle: string
	keyword: string
	keywordHash: string;
	keywordTraffic: number | null
	internalLinks: number | null
	externalLinks: number | null
	images: number | null
	wordCount: number | null
	isProcessing: boolean
	isGenerated: boolean
	isPosted: boolean
	isFailed: boolean
	isArchived: boolean
	postLink: string
	isUserAdded: boolean
	postedTo: string
}

interface InitialSortingState {
	id: string;
	desc: boolean;
}

export default function ShowTitlesForKeyword() {
	// ----------------------- REFS -----------------------
	const navigate = useNavigate();
	const location = useLocation();

	// ----------------------- NON STATE CONSTANTS -----------------------
	const pageSizes = [5, 10, 15, 30, 50, 100, 500];

	// ----------------------- STATES -----------------------
	const [tableData, setTableData] = useState<Array<ArticleTitle>>([]);
	const [requestModalActive, setRequestModalActive] = useState(false);
	const [integrationModalActive, setIntegrationModalActive] = useState(false);
	const [modalText, setModalText] = useState("");
	const [integrationWithUniqueID, setIntegrationWithUniqueID] = useState<Array<AllIntegrations>>([]);
	const [selectedIntegration, setSelectedIntegration] = useState("");
	const [showCreateCustomTitleModal, setShowCreateCustomTitleModal] = useState(false);
	const [customTitle, setCustomTitle] = useState("");
	const [initialSortingState, setInitialSortingState] = useState<InitialSortingState[]>([{
		id: "wordCount", desc: true
	}])
	const [userVerified, setUserVerified] = useState(false);
	const [titlesGenerated, setTitlesGenerated] = useState(false);
	const [openDropdown, setOpenDropdown] = useState("");
	const [ArticleGenProgressMap, setArticleGenProgressMap] = useState({}); // Object to track progress of each task
	const [ArticleGenProgressMessageMap, setArticleGenProgressMessageMap] = useState({}); // Object to track messages of each task

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);
	const taskPollingIntervals = useRef({});  // To track intervals for each task
	const navigatedArticles = useRef(new Set<string>());

	// ----------------------------- LOADER -----------------------------
	const pageData: ServerData = useLoaderData() as ServerData;
	const { revalidate } = useRevalidator();

	// ----------------------- MUTATIONS -----------------------
	const generateArticle = useMutation(generateV2ArticleMutation);
	const postArticle = useMutation(postArticleMutation);
	const archiveSelectedArticles = useMutation(archiveBulkArticleMutation);
	const createCustomTitleForKeyword = useMutation(createCustomTitleForKeywordMutation);
	const titlesForKeywordMutation = useMutation(GenerateTitlesFromKeyword);

	// --------- For seo programmatic ----------
	const isListOfTitlesRoute = location.pathname.includes("/list-of-titles");
	const [editableTitles, setEditableTitles] = useState<EditableTitle[]>([]);
	const [selectedRows, setSelectedRows] = useState<RowModel<RowData>>();
	const [bulkActionsEnabled, setBulkActionsEnabled] = useState(true);
	const generateSelectedArticles = useMutation(generateBulkV2ArticleMutation);
	const allBulkOptions = useMemo(() => [
		{
			text: "Generate article",
		},
		{
			text: "Publish",
		},
		{
			text: "Archive",
		},
		{
			text: "Unarchive",
		},

	], []);
	const [availableBulkActions, setAvailableBulkActions] = useState(allBulkOptions);

	useEffect(() => {
		if (isListOfTitlesRoute && pageData?.titles_data) {
			const formattedTitles = pageData.titles_data.map((title) => ({
				title: title.articleTitle,
				isEditing: false,
			}));
			setEditableTitles(formattedTitles);
		}
	}, [isListOfTitlesRoute, pageData]);

	const toggleEdit = (index: number) => {
		setEditableTitles((prev) =>
			prev.map((item, i) =>
				i === index ? { ...item, isEditing: !item.isEditing } : item
			)
		);
	};

	const handleTitleChange = (index: number, value: string) => {
		setEditableTitles((prev) =>
			prev.map((item, i) => (i === index ? { ...item, title: value } : item))
		);
	};

	const handleSave = (index: number) => {
		setEditableTitles((prev) =>
			prev.map((item, i) =>
				i === index ? { ...item, isEditing: false } : item
			)
		);
	};

	const integrationLogoPrimary = {
		"wordpress": wordpressIconPrimary,
		"webflow": webflowIconPrimary,
		"wix": wixIconPrimary
	}

	// ----------------------- EFFECTS -----------------------
	useEffect(() => {
		document.title = "Articles | Abun"
	}, []);

	useEffect(() => {
		if (pageData) {
			if (pageData['titles_data']) {
				setTitlesGenerated(true);
				// filter out the articles that do not have props.keyword.keyword
				pageData['titles_data'] = pageData['titles_data'].filter((article: ArticleTitle) => {
					return article.keyword === pageData.keyword || article.keywordHash === pageData.keywordHash;
				})

				// sort the data according to the descending order of keyword traffic
				pageData['titles_data'].sort((a: ArticleTitle, b: ArticleTitle) => {
					if (a.keywordTraffic === null) {
						return 1;
					} else if (b.keywordTraffic === null) {
						return -1;
					} else {
						return b.keywordTraffic - a.keywordTraffic;
					}
				});

				// sorting the data so that user added titles are shown first
				pageData['titles_data'].sort((a: ArticleTitle, b: ArticleTitle) => {
					if (a.isUserAdded && !b.isUserAdded) {
						return -1;
					} else if (!a.isUserAdded && b.isUserAdded) {
						return 1;
					} else {
						return 0;
					}
				});
			}
			setIntegrationWithUniqueID((pageData as any)['all_integrations_with_unique_id']);
			setUserVerified((pageData as any)['user_verified']);

			// hide the table column according to the selected tab
			const tab_to_hide = document.querySelector("#article-titles-table .hide-column-child")?.parentElement || document.querySelector("#article-titles-table .hide-column");

			// filter the data according to the selected tab
			while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
				tab_to_hide.classList.remove("hide-column");
			}
			setTableData((pageData as any)['titles_data'].filter((article: ArticleTitle) => {
				return !article.isArchived;
			}));

			// if any of the article is generating, and windows.href does not contains `/articles/edit/` we refetch the data after 15 seconds
			if ((pageData as any)['titles_data'].some((article: ArticleTitle) => article.isProcessing)) {
				setTimeout(() => {
					if (!window.location.href.includes("/articles/edit/")) {
						revalidate();
					}
				}, 15000);
			}
		}
	}, [pageData]);

	const handleBackClick = () => {
		if (isListOfTitlesRoute) {
			navigate('/title-project');
		} else {
			// If not on the 'listoftitles' route, navigate to the keywordResearch route
			navigate(`${pageURL['keywordResearch']}?keywordProjectId=${pageData.keyword_project_id}`);
		}
	};

	// =========================================================
	// ----------------------- MAIN CODE -----------------------
	// =========================================================
	function generateArticleHandler(articleUID: string) {
		// Step 1: Start the article generation process
		generateArticle.mutate(articleUID, {
			onSuccess: (data) => {
				const responseData = data?.data;

				if (responseData?.status === "sent_for_processing") {
					// Store articleUID in localStorage for tracking
					localStorage.setItem(`articleGen-${articleUID}`, articleUID);

					// Start polling for progress using articleUID
					pollArticleProgress(articleUID);
				} else if (responseData?.status === "rejected") {
					// Handle rejection cases
					const reason = responseData.reason;
					if (reason === "max_limit_reached") {
						failAlertRef.current?.show(
							"Article generation request failed. " +
							"You have reached your max article generation limit for the month."
						);
					} else {
						failAlertRef.current?.show(`Article generation request failed. Error ID: ${reason}`);
					}
				} else {
					failAlertRef.current?.show(
						`Article generation request returned unknown status ${responseData?.status}. Please contact us if there's any issue.`
					);
				}
				setInitialSortingState(initialSortingState);
			},
			onError: (error: Error) => {
				console.error("Article generation request error:", error);
				failAlertRef.current?.show("Article generation request failed. Please try again later");
				setTimeout(() => {
					failAlertRef.current?.close();
				}, 5000);
			},
		});
	}

	// Step 2: Poll for article generation progress using articleUID
	const pollArticleProgress = (articleUID: string) => {
		if (taskPollingIntervals.current[articleUID]) return; // Avoid duplicate polling
		// Poll every 2 seconds
		taskPollingIntervals.current[articleUID] = setInterval(() => {
			getArticleProgress(articleUID).then((res) => {
				if (res.data.progress) {
					// Update progress and message
					const progressInfo = res.data.progress;
					setArticleGenProgressMap(prev => {
						const updatedMap = { ...prev, [articleUID]: progressInfo.percent };
						localStorage.setItem("articleGenProgressMap", JSON.stringify(updatedMap)); // Persist
						return updatedMap;
					});
					setArticleGenProgressMessageMap(prev => {
						const updatedMap = { ...prev, [articleUID]: progressInfo.description };
						localStorage.setItem("articleGenProgressMessageMap", JSON.stringify(updatedMap)); // Persist
						return updatedMap;
					});

					if (progressInfo.percent === 100) {
						// Task is complete
						clearInterval(taskPollingIntervals.current[articleUID]);
						setTimeout(() => {
							navigate(`/articles/edit/${articleUID}/`);
							setTimeout(() => cleanupArticleProgress(articleUID), 3000);
						}, 3000);
					}
				}
			}).catch((error) => {
				console.error("Error fetching article progress:", error);
				clearInterval(taskPollingIntervals.current[articleUID]);
				failAlertRef.current?.show("Error tracking article generation progress. Please try again later.");
				cleanupArticleProgress(articleUID);
			});
		}, 2000);
	};

	useEffect(() => {
		const storedProgress = JSON.parse(localStorage.getItem("articleGenProgressMap") || "{}");
		const storedMessages = JSON.parse(localStorage.getItem("articleGenProgressMessageMap") || "{}");

		setArticleGenProgressMap(storedProgress);
		setArticleGenProgressMessageMap(storedMessages);

		// Restart polling or handle navigation
		Object.keys(storedProgress).forEach((articleUID) => {
			const progress = storedProgress[articleUID];
			if (progress < 100) {
				pollArticleProgress(articleUID); // Restart polling
			} else if (progress === 100 && !navigatedArticles.current.has(articleUID)) {
				navigatedArticles.current.add(articleUID);
				navigate(`/articles/edit/${articleUID}/`);
				cleanupArticleProgress(articleUID);
			}
		});
	}, []);

	//Step 3: Cleanup after completion or error
	const cleanupArticleProgress = (articleUID: string) => {
		setArticleGenProgressMap(prev => {
			const updatedMap = { ...prev };
			delete updatedMap[articleUID];
			return updatedMap;
		});

		setArticleGenProgressMessageMap(prev => {
			const updatedMap = { ...prev };
			delete updatedMap[articleUID];
			return updatedMap;
		});


		// Clear the interval
		if (taskPollingIntervals.current[articleUID]) {
			clearInterval(taskPollingIntervals.current[articleUID]);
			delete taskPollingIntervals.current[articleUID];
		}

		// // Remove the articleUID from localStorage
		localStorage.removeItem(`articleGen-${articleUID}`);
		localStorage.removeItem("articleGenProgressMap");
		localStorage.removeItem("articleGenProgressMessageMap");

	};

	function postToBlogHandler(articleUID: string) {
		failAlertRef.current?.close();
		successAlertRef.current?.close();

		setModalText("Posting to your website blog. Please wait...");
		setRequestModalActive(true);
		postArticle.mutate({ articleUID: articleUID, selectedIntegration: selectedIntegration, selectedIntegrationUniqueID: "", selectedCategories: 1 }, {
			onSuccess: () => {
				setRequestModalActive(false);
				revalidate();
				successAlertRef.current?.show(`Article ${articleUID} was posted to your site successfully!`);
			},
			onError: () => {
				setRequestModalActive(false);
				failAlertRef.current?.show(
					"Oops! Something went wrong. Check your Email for more details or contact us if the issue persists."
				);
			}
		})
	}

	function setIntegrationAndHideDropDownContent(integrate: string) {
		setSelectedIntegration(integrate);
		setOpenDropdown("");
	}

	const openUrlInNewTab = (url: string) => {
		window.open(url, "_blank");
	}

	function getIntegrationName(integration: string) {
		if (integration.includes("wordpress")) {
			return "wordpress";
		} else if (integration.includes("webflow")) {
			return "webflow";
		} else {
			return "wix";
		}
	}

	function truncateSiteDomain(domain: string) {
		if (domain.includes("Webflow")) {
			domain = domain.replace(new RegExp("Webflow - ", 'g'), '');
		} else if (domain.includes("Wix")) {
			domain = domain.replace(new RegExp("Wix - ", 'g'), '');
		} else {
			domain = domain.replace(new RegExp("Wordpress - ", 'g'), '');
		}

		if (domain.length <= 20) {
			return domain;
		} else {
			domain = domain.substring(0, 17) + "...";
		}

		return domain;
	}

	// ================== Generate table data and render AbunTable component ==================
	const columnHelper = createColumnHelper<ArticleTitle>();

	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: ArticleTitle) => row.articleTitle, {
			id: 'articleTitle',
			header: "Article Title",
			cell: props => {
				if (isListOfTitlesRoute) { // on condition true on hover show edit btn
					// Editable titles logic for the `listoftitles` route
					const index = props.row.index;
					const row = editableTitles[index];
					return row?.isEditing ? (
						<input
							type="text"
							value={row.title}
							onChange={(e) => handleTitleChange(index, e.target.value)}
							onBlur={() => handleSave(index)}
							onKeyDown={(e) => {
								if (e.key === "Enter") handleSave(index);
							}}
							autoFocus
						/>
					) : (
						<div>
							{row?.title}{" "}
							<button
								onClick={() => toggleEdit(index)}
								className="seo-titles-edit-button"
							>
								✏️
							</button>
						</div>
					);
				} else {
					// Default behavior for other routes
					return (
						<CustomContextMenu
							url={`/articles/edit/${props.row.original.articleUID}`}
							CtrlOrMetaClick={() => {
								openUrlInNewTab(`/articles/edit/${props.row.original.articleUID}`);
							}}
							normalClick={() => {
								navigate(`/articles/edit/${props.row.original.articleUID}`);
							}}
						>
							<span>{props.row.original.articleTitle}</span>
						</CustomContextMenu>
					);
				}
			},
			enableGlobalFilter: true,
			enableSorting: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.wordCount, {
			id: 'wordCount',
			header: "Words",
			cell: (info) => {
				if (info.getValue() === null || info.getValue() === 0) {
					return "---";
				} else {
					return info.getValue();
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		columnHelper.display({
			id: 'generate_articles',
			header: () => "Action",
			cell: cellProps => {
				let processing: boolean = cellProps.row.original.isProcessing;
				let generated: boolean = cellProps.row.original.isGenerated;
				let failed: boolean = cellProps.row.original.isFailed;
				if (generated) {
					return (
						<CustomContextMenu
							url={`/articles/edit/${cellProps.row.original.articleUID}`}
							CtrlOrMetaClick={() => {
								openUrlInNewTab(`/articles/edit/${cellProps.row.original.articleUID}`);
							}}
							normalClick={() => {
								navigate(`/articles/edit/${cellProps.row.original.articleUID}`);
							}}>
							<button
								style={{ width: "100px" }}
								className="button is-success is-outlined is-small more-rounded-borders"
							>
								View
							</button>
						</CustomContextMenu>
					)
				} else if (processing) {
					const progressMessage = ArticleGenProgressMessageMap[cellProps.row.original.articleUID];
					if (!progressMessage) {
						return (
							<LinkButton
								linkTo={`/articles/edit/${cellProps.row.original.articleUID}/`}
								text={"Generating..."}
								type={"primary"}
								width={"100px"}
								outlined={true}
								additionalClassList={["is-small", "more-rounded-borders"]}
							/>
						);
					}

					return (
						<AbunButton
							className={"is-outlined is-small comp-research-table-button"}
							type={
								ArticleGenProgressMap[cellProps.row.original.articleUID] < 100
									? "primary"
									: "success"
							}
							clickHandler={() => {
								generateArticleHandler(cellProps.row.original.articleUID);
							}}
							disabled={generateArticle.isLoading || !userVerified}
							progress={ArticleGenProgressMap[cellProps.row.original.articleUID] || 0} // Using progress from your map
							progressColor="#007BFF" // Define your progress color
							width = {"100px"}
						>
							{progressMessage}
						</AbunButton>
					);
				} else if (failed) {
					return <GenericButton text={"Failed. Retry"}
						type={"danger"}
						width={"100px"}
						disable={generateArticle.isLoading}
						additionalClassList={["is-small", "more-rounded-borders"]}
						clickHandler={() => {
							generateArticleHandler(cellProps.row.original.articleUID);
						}} />
				} else {
					return <div data-tooltip-id="generate-article" data-tooltip-content="Verify email to create article">
						<AbunButton
							className={"is-outlined is-small comp-research-table-button"}
							type={
								ArticleGenProgressMap[cellProps.row.original.articleUID] < 100
									? "primary"
									: "success"
							}
							clickHandler={() => {
								generateArticleHandler(cellProps.row.original.articleUID);
							}}
							disabled={generateArticle.isLoading || !userVerified}
							progress={ArticleGenProgressMap[cellProps.row.original.articleUID] || 0} // Using progress from your map
							progressColor="#007BFF" // Define your progress color
							width = {"100px"}
						>
							{ArticleGenProgressMessageMap[cellProps.row.original.articleUID] || "Create Article"}
						</AbunButton>
						{!userVerified && <Tooltip id="generate-article" />}
					</div>
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center',
			}
		}),
		columnHelper.display({
			header: "Publish",
			id: 'post_article',
			cell: cellProps => {
				let posted: boolean = cellProps.row.original.isPosted;
				let generated: boolean = cellProps.row.original.isGenerated;
				let postedTo: string = cellProps.row.original.postedTo;
				let archived: boolean = cellProps.row.original.isArchived;
				let articleUID: string = cellProps.row.original.articleUID;
				if (posted) {
					return <>
						<a className={"view-on-blog-link"} href={cellProps.row.original.postLink} data-tooltip-id="my-tooltip" target="_blank" rel="noreferrer" data-tooltip-content="View on Blog" >
							<img className={"image"} src={postedTo === "wordpress" ? wordpressIconSuccess : webflowIconSuccess} alt={postedTo === "wordpress" ? "wordpress-icon" : "webflow-icon"} />
						</a>
						<Tooltip id="my-tooltip" />
					</>
				} else if (generated) {
					return <>
						<div className="publish-container is-flex is-align-items-center is-justify-content-center">
							<button className={"publish-to-blog-btn is-flex is-align-items-center is-justify-content-center"} data-tooltip-id="publish-to-blog" data-tooltip-content={integrationWithUniqueID.length > 0 ? "Publish to Blog" : "Connect & Publish Now"}
								onClick={() => {
									if (integrationWithUniqueID.length > 0) {
										postToBlogHandler(articleUID);
									} else {
										setIntegrationModalActive(true);
									}
								}}>
								<img className={"image"} src={integrationWithUniqueID.length === 0 ? articleIntegrationIcon : integrationLogoPrimary[getIntegrationName(selectedIntegration)]} alt={selectedIntegration === "webflow" ? "webflow-icon" : "wordpress-icon"} />
							</button>
							{integrationWithUniqueID.length > 1 &&
								<div className="dropdown">
									<button className={`dropdown-icon ${openDropdown === articleUID ? "rotate" : ""}`} onClick={() => setOpenDropdown(openDropdown === articleUID ? "" : articleUID)}>&#9662;</button>
									<div className={`dropdown-content ${openDropdown === articleUID ? "show" : ""}`}>
										<h6 className={"dropdown-header"}>
											Choose Integration
										</h6>
										{
											integrationWithUniqueID.map((integration, index) => (
												<div key={index} className={"dropdown-item"} onClick={() => setIntegrationAndHideDropDownContent(integration.integrationName)}>
													<input type="radio" name="integration" id={integration.integrationName} checked={selectedIntegration === integration.integrationName} onChange={() => { }} />
													{
														<p>{truncateSiteDomain(integration.integrationName.charAt(0).toUpperCase() + integration.integrationName.slice(1))}</p>
													}
												</div>
											))
										}
									</div>
								</div>
							}
						</div>
						<Tooltip id="publish-to-blog" />
					</>
				} else if (archived) {
					return <GenericButton text={"Unarchive"}
						type={"success"}
						disable={archiveSelectedArticles.isLoading}
						additionalClassList={["is-small", "more-rounded-borders"]}
						clickHandler={() => {
							archiveSelectedArticles.mutate({ articlesUID: [cellProps.row.original.articleUID], archiveType: "unarchive" }, {
								onSuccess: () => {
									successAlertRef.current?.show("Article was unarchived successfully!");
									revalidate();
								},
								onError: () => {
									failAlertRef.current?.show("Failed to unarchive article. Please try again after some time.");
								}
							});
						}} />
				} else {
					return <></>
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		})
	]

	if (isListOfTitlesRoute) { // on condition true show bulk action
		if (bulkActionsEnabled) {
			// Add checkbox if bulk actions are enabled
			columnDefs.splice(0, 0, columnHelper.accessor((row: ArticleTitle) => row.articleTitle, {
				id: 'checkbox',
				header: ({ table }) => (
					<IndeterminateCheckbox
						{...{
							checked: table.getIsAllRowsSelected(),
							indeterminate: table.getIsSomeRowsSelected(),
							onChange: table.getToggleAllRowsSelectedHandler(),
						}}
					/>
				),
				cell: ({ row }) => (
					<IndeterminateCheckbox
						{...{
							checked: row.getIsSelected(),
							disabled: !row.getCanSelect(),
							indeterminate: row.getIsSomeSelected(),
							onChange: row.getToggleSelectedHandler(),
						}}
						name={"articleSelection"}
						value={row.original.articleUID}
					/>
				),
				enableGlobalFilter: true,
				enableSorting: false,
			}));
		} else {
			// Remove the checkbox if bulk actions are disabled
			columnDefs.splice(0, 0);
		}
	}

	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	return (
		<div className={"keyword-project-container is-flex w-100 is-align-items-center is-justify-content-space-between is-flex-direction-column"}>
			<div className={"keyword-project-header"}>
				<svg className={"back-btn"} onClick={handleBackClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
					<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
				</svg>
			</div>
			{/* ------------------------------ INTEGRATION MODAL ------------------------------ */}
			<AbunModal active={integrationModalActive}
				headerText={""}
				closeable={true}
				hideModal={() => {
					setIntegrationModalActive(false)
				}}>
				<div className={"has-text-centered"}>
					<h1 className={"is-size-3"}>Publish articles to your blog page!</h1>
					<p className={"mt-4"}>
						Start publishing articles to your blog page by setting up an Integration for your website.
						You can find it under <b>Settings</b> {">"} <b>Integration & Scheduling</b>
					</p>
					<LinkButton text={"Go to Settings"}
						linkTo={pageURL['settings'] + "?tab=integration"}
						type={"primary"}
						additionalClassList={["mt-5"]} />
				</div>
			</AbunModal>



			<div className={"is-flex is-flex-direction-column is-align-items-center is-justify-content-center"} style={titlesGenerated ? { height: "auto", width: "100%" } : { height: "70vh", width: "100%" }}>
				<div className="keyword-title-table-header">
					<h1>Choose Title to create Article for</h1>
					<h2>Keyword:</h2><span> {pageData.keyword}</span>
					<p>
						{isListOfTitlesRoute ? `Pattern: ${pageData.pattern}` : `Total Keyword Traffic: ${pageData.keywordTraffic}`}
						{!isListOfTitlesRoute && (<img
							loading="lazy"
							width="20"
							style={pageData.locationIsoCode === "zz" ? { position: "absolute", marginLeft: "3px" } : { marginLeft: "5px" }}
							srcSet={pageData.locationIsoCode !== "zz" ? `https://flagcdn.com/32x24/${pageData.locationIsoCode.toLowerCase()}.png 2x` : "https://img.icons8.com/?size=100&id=3685"}
							src={pageData.locationIsoCode !== "zz" ? `https://flagcdn.com/16x12/${pageData.locationIsoCode.toLowerCase()}.png` : "https://img.icons8.com/?size=100&id=3685"}
							alt={pageData.locationIsoCode}
						/>)}
					</p>
				</div>

				{/* Below TSX component conditionally renders UI elements based on the state of article title generation.
				1. If titles have not been generated, it shows buttons to either generate article titles or write a custom title.
					- The "Generate Article Titles" button triggers an asynchronous operation to generate titles based on a keyword hash.
					- The "Write Custom Title" button opens a modal for custom title input.
				2. If loading or no table data is present, it displays a loading indicator.
				3. On error, it shows an error message.
				4. If titles are generated and data is available, it displays a table (`AbunTable`) of article titles with sorting and pagination options, and a button to write a custom title. 
				*/}
				{
					tableData.length == 0 ?
						localStorage.getItem(`titleGenTask-${pageData.keywordHash}`) ?
							<div className={"is-flex is-justify-content-center mt-4"}>
								<h2 style={{ marginTop: "40%" }} className="is-size-3">Generating Article Titles... <Icon additionalClasses={["ml-2 mb-2"]} iconName="spinner" /> </h2>
							</div> :
							// Display buttons to generate article titles or write custom title
							<div className={"is-flex is-justify-content-center mt-4"}>
								<GenericButton text={"Generate Article Titles"}
									type={"primary"}
									disable={titlesForKeywordMutation.isLoading}
									additionalClassList={["is-small"]}
									clickHandler={() => {
										setModalText("Generating article titles. Please wait...");
										setRequestModalActive(true);
										titlesForKeywordMutation.mutate({
											keyword_hash: pageData.keywordHash,
											location: pageData.locationIsoCode.toLowerCase()
										}, {
											onSuccess: () => {
												setRequestModalActive(false);
												setTitlesGenerated(true);
												revalidate();
												successAlertRef.current?.show("New Titles have been generated successfully!");
												setTimeout(() => {
													successAlertRef.current?.close();
												}, 5000);
											},
											onError: ((error: Error) => {
												setRequestModalActive(false);
												if (error.message) {
													failAlertRef.current?.show(
														`Failed to generate new titles. ${error.message}`
													);
												} else {
													failAlertRef.current?.show(
														`Failed to generate new titles. Please try again later.`
													);
												}
												setTimeout(() => {
													failAlertRef.current?.close();
												}, 5000);
											})
										});
									}}
								/>
								<span className="mr-5 ml-5">or</span>
								<GenericButton text={"Write Custom Title"}
									type={"primary"}
									disable={createCustomTitleForKeyword.isLoading}
									clickHandler={() => setShowCreateCustomTitleModal(true)}
									additionalClassList={["is-small"]} />
							</div>
						// : error ? // Display error message if an error occurs
						// <div>
						// 	<p>Error! Failed to load data. Please try again later.</p>
						// </div>
						// : (isLoading || tableData.length === 0) ? // Display loading indicator if data is loading or no data is present
						// <div className={"loadingDataCard mt-4"} style={{ width: "100%" }}>
						// 	<div className={"card-content"}>
						// 		<div className={"content is-flex is-justify-content-center"}>
						// 			<p style={{ textAlign: "center", fontSize: "1.3rem" }}>
						// 				Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
						// 			</p>
						// 		</div>
						// 	</div>
						// </div>
						: // Display the table of article titles if data is available

						(
							<>
								{isListOfTitlesRoute && ( // loading for list of title
									<AbunModal
										active={requestModalActive || createCustomTitleForKeyword.isLoading}
										headerText={""}
										closeable={false}
										hideModal={() => setRequestModalActive(false)}
									>
										<div className="loadingData w-100 is-flex is-justify-content-center is-align-items-center">
											<img className="image" src={loadingIcon} alt="loading" />
										</div>
										<p className="is-size-4 has-text-centered mb-4">{modalText}</p>
									</AbunModal>
								)}
								<AbunTable
									tableContentName={isListOfTitlesRoute ? "Seo Titles" : "Article Titles"}  // condition table render for list of title for seo and article title. 
									id="article-titles-table"
									tableData={tableData}
									columnDefs={columnDefs}
									pageSizes={pageSizes}
									initialPageSize={pageSizes[6]}
									enableSorting={true}
									defaultSortingState={initialSortingState}
									noDataText={
										isListOfTitlesRoute
											? "No SEO titles found. Generate more titles to manage SEO."
											: "No articles found. Generate more titles to create articles."
									}
									rowCheckbox={isListOfTitlesRoute ? true : undefined}
									selectedRowsSetter={isListOfTitlesRoute ? selectedRowsSetter : undefined}
									bulkActions={isListOfTitlesRoute && selectedRows?.rows.length ? availableBulkActions : []}
									bulkActionsEnabled={isListOfTitlesRoute ? bulkActionsEnabled : false}
									isListOfTitlesRoute={isListOfTitlesRoute}
									applyAction={
										isListOfTitlesRoute
											? (action) => {
												if (action === "Generate article") {
													setModalText("Processing request. Please wait...");
													setRequestModalActive(true);
													const articleUIDs: Array<string> =
														selectedRows?.rows.map((row) => {
															return (row.original as ArticleTitle).articleUID;
														}) || [];
													generateSelectedArticles.mutate(articleUIDs, {
														onSuccess: () => {
															setRequestModalActive(false);
															revalidate();
															successAlertRef.current?.show(
																"Articles are being generated. Please wait for a moment."
															);
															setTimeout(() => {
																successAlertRef.current?.close();
															}, 5000);
														},
														onError: () => {
															setRequestModalActive(false);
															revalidate();
															failAlertRef.current?.show(
																"Failed to generate articles. Please try again after some time."
															);
															setTimeout(() => {
																successAlertRef.current?.close();
															}, 5000);
														},
													});
												}
											}
											: undefined
									}
									buttons={[
										{
											text: isListOfTitlesRoute
												? "Add Custom Title"
												: "Write Custom Title",
											type: "primary",
											isDisabled: false,
											clickHandler: () => setShowCreateCustomTitleModal(true),
											extraClassName: "is-small is-justify-content-space-between",
										},
									]}
								/>
							</>
						)
				}
			</div>

			{/* ------------------------------ CREATE CUSTOM TITLE MODAL ------------------------------ */}
			<AbunModal active={showCreateCustomTitleModal}
				headerText={"Write a custom title keyword: " + pageData.keyword}
				closeable={true}
				hideModal={() => {
					setShowCreateCustomTitleModal(false)
				}}>
				<div className={"has-text-centered"}>
					<input type="text" className={"input "} placeholder={"Enter your custom title here..."} onChange={(e) => {
						setCustomTitle(e.target.value);
					}} />
					<AbunButton type={"success"}
						className={"mt-4"}
						disabled={createCustomTitleForKeyword.isLoading}
						clickHandler={() => {
							setShowCreateCustomTitleModal(false);
							createCustomTitleForKeyword.mutate({
								keyword: pageData.keyword,
								keywordHash: pageData.keywordHash,
								customArticleTitle: customTitle
							}, {
								onSuccess: () => {
									setTitlesGenerated(true);
									revalidate();
									successAlertRef.current?.show("Custom title added successfully!");
									setTimeout(() => {
										successAlertRef.current?.close();
									}, 5000);
								},
								onError: () => {
									failAlertRef.current?.show("Failed to add custom title. Please try again after some time.");
									setTimeout(() => {
										failAlertRef.current?.close();
									}, 5000);
								}
							});
						}}>
						Proceed
					</AbunButton>
				</div>
			</AbunModal>

			<SuccessAlert ref={successAlertRef} />
			<ErrorAlert ref={failAlertRef} />
		</div>
	)
}
