/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { icpIndustryQuery } from "../../utils/api";
import ConnectWebsiteLoading from "./ConnectWebsiteLoading";
import GenericButton from "../../components/GenericButton/GenericButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

interface WebsiteICPProps {
	nextStage: () => void
	getWebsiteData: () => any
	updateWebsiteData: (key: any, value: any) => void
	getWebsiteTempData: () => Object
	updateWebsiteTempData: (key: any, value: any) => void
	setStage: (stage: number) => void
}

interface ICPResult {
	icp: string
	industry: string
}

export default function WebsiteICP(props: WebsiteICPProps) {
	// -------------------- NON STATE CONSTANTS --------------------
	const industryMinCharCount = 4;
	const icpTextMinCharCount = 4;
	const domain: string = props.getWebsiteData()['domain'];
	const title: string = props.getWebsiteData()['title'];
	const description: string = props.getWebsiteData()['description'];
	const tempFlag: string = props.getWebsiteTempData()['language']; // Temporary flag data 
	const enableAPI = (domain === tempFlag) ? false : true;

	// -------------------- STATES --------------------
	// const [skipQuery, setSkipQuery] = useState(false);
	const [userInputRequired, setUserInputRequired] = useState(false);
	const [industryValue, setIndustryValue] = useState(props.getWebsiteTempData()['industry'] ? props.getWebsiteTempData()['industry'] : '');
	const [icpValue, setIcpValue] = useState(props.getWebsiteTempData()['icp'] ? props.getWebsiteTempData()['icp'] : '');

	// -------------------- REFS --------------------
	const industryMinCountLabel = useRef<HTMLLabelElement>(null);
	const icpTextMinCountLabel = useRef<HTMLLabelElement>(null);
	const errorAlertRef = useRef<any>(null);

	// -------------------- EFFECTS --------------------
	const { data, isLoading, error, refetch } = useQuery(icpIndustryQuery(domain, title, description, enableAPI));

	// So that it only makes the query once.
	// if (!skipQuery) {
	// 	refetch().then(() => {
	// 	});
	// }

	useEffect(() => {
		if (data && enableAPI) {
			let icpIndustryResult: ICPResult = (data as any)['data'];
			if (
				(icpIndustryResult.icp.length >= icpTextMinCharCount) &&
				(icpIndustryResult.industry.length >= industryMinCharCount)
			) {
				// Save data and move to next stage
				props.updateWebsiteData('icp', icpIndustryResult.icp);
				props.updateWebsiteData('industry', icpIndustryResult.industry);
				props.updateWebsiteTempData('language', domain);
				props.updateWebsiteTempData('icp', '');
				props.updateWebsiteTempData('industry', '');
				props.nextStage();
			} else {
				// Could not find required data
				setIcpValue(icpIndustryResult.icp);
				setIndustryValue(icpIndustryResult.industry);

				// Temporary Data
				props.updateWebsiteTempData('language', domain);
				props.updateWebsiteTempData('icp', icpIndustryResult.icp);
				props.updateWebsiteTempData('industry', icpIndustryResult.industry);
				setUserInputRequired(true);
			}
			// setSkipQuery(true);
		} else if (icpValue && industryValue) {
			setIcpValue(icpValue);
			setIndustryValue(industryValue);
			setUserInputRequired(true);
		}
		else if (!enableAPI && !icpValue && !industryValue) {
			props.nextStage();
		}
	}, [data]);

	// useEffect(() => {
	// 	setSkipQuery(true);
	// }, [error])

	function buttonClickHandler() {
		// remove previous error messages
		industryMinCountLabel.current?.classList.remove('show');
		icpTextMinCountLabel.current?.classList.remove('show');
		errorAlertRef.current.close();

		if (industryValue.length < industryMinCharCount) {
			errorAlertRef.current.show("Please fill in both Industry & ICP fields correctly. " +
				"We need it to generate appropriate content for your site.")
			industryMinCountLabel.current?.classList.add('show');
		} else if (icpValue.length < icpTextMinCharCount) {
			errorAlertRef.current.show("Please fill in both Industry & ICP fields correctly. " +
				"We need it to generate appropriate content for your site.")
			icpTextMinCountLabel.current?.classList.add('show');
		} else {
			// Save details and proceed to next stage
			props.updateWebsiteData('icp', icpValue);
			props.updateWebsiteData('industry', industryValue);

			// Temporary Data
			props.updateWebsiteTempData('icp', icpValue);
			props.updateWebsiteTempData('industry', industryValue);
			props.nextStage();
		}
	}

	function handleInputChange(value: string) {
		let industry = value.replaceAll(new RegExp(/[^\w\s]/, 'g'), "");
		setIndustryValue(title)
		props.updateWebsiteTempData('industry', industry);
	}

	if (isLoading && enableAPI) {
		return <ConnectWebsiteLoading text={"Processing. Please wait..."} />

	} else if (error && enableAPI) {
		props.setStage(0);
		return <p>Oops! Something went wrong :(</p>

	}
	else {
		if (userInputRequired || !enableAPI) {
			return (
				<>
					<p className="connectwebsite-title">What's your Ideal Customer Profile (ICP) & Industry
						for <b>{domain}</b></p>
					<div className="connectwebsite-domain-form">
						<div className="field">
							<label className="label">Website Industry&nbsp;<span className="has-text-danger">*</span></label>
							<div className="control">
								<input type={"text"}
									className="input"
									value={industryValue}
									onChange={(e) => handleInputChange(e.target.value)}
									placeholder={"Ex. Graphic Design"} />
							</div>
							<label className={`input-min-counter-label`}
								ref={industryMinCountLabel}>
								Min. {industryMinCharCount} characters required.
							</label>
						</div>
						<div className="field">
							<label className="label">Ideal Customer Profile&nbsp;<span className="has-text-danger">*</span></label>
							<div className="control">
								<textarea className="textarea"
									value={icpValue}
									placeholder={"Small to medium-sized businesses in need of unlimited graphic design services."}
									onChange={e => {
										setIcpValue(e.target.value)
										props.updateWebsiteTempData('icp', e.target.value);
									}}></textarea>
							</div>
							<label className={`input-min-counter-label`}
								ref={icpTextMinCountLabel}>
								Min. {icpTextMinCharCount} characters required.
							</label>
						</div>
						<GenericButton text={"PROCEED"}
							type={"success"}
							clickHandler={buttonClickHandler}
							additionalClassList={['is-block', 'ml-auto', 'mr-auto', 'mt-6']} />
					</div>
					{/* shows error notification if error message is set to non-empty string using its show() method */}
					<ErrorAlert ref={errorAlertRef} />
				</>
			)
		} else {
			return <ConnectWebsiteLoading text={"Processing. Please wait..."} />
		}
	}
}
