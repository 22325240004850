import { useMutation } from '@tanstack/react-query';
import { useRef, useState, useEffect, useContext } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import loadingIcon from "../../assets/images/loadingIcon.webp";
import AbunModal from '../../components/AbunModal/AbunModal';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import GenericButton from '../../components/GenericButton/GenericButton';
import Survey from "../../components/Survey/Survey";
import { APIError, generateV2ArticleMutation, makeApiRequest } from '../../utils/api';
import { ConnectWebsite } from "../../pages/KeywordsResearchV2/KeywordResearch";
import {BasePageData} from "../../pages/Base/Base";
import { SurveyContext } from "../../pages/Login/SurveyContext";
import './CreateArticle.scss';

interface CreateArticlePageData {
  verified: boolean
	survey_completed: boolean
  article_context: string | null
}

const CreateArticle = () => {
  // ---------------------------- PAGE DATA ----------------------------
	const { basePageData, pageData } = useLoaderData() as {
      basePageData: BasePageData;
      pageData: CreateArticlePageData;
  };
  
  const [showConnectWebsiteModal, setShowConnectWebsiteModal] = useState(
    basePageData.website_list.length >= 1 &&
    basePageData.website_list[0].domain.startsWith("default-") &&
    basePageData.website_list[0].domain.endsWith(".xyz")
  );

  // -------------------------- STATES --------------------------
  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('us');
  const [maxTitles, setMaxTitles] = useState(8);
  const [serpTitles, setSerpTitles] = useState([]);
  const [creativeTitles, setCreativetitles] = useState([]);
  const [articleTitle, setArticleTitle] = useState('');
  const [articleContext, setArticleContext] = useState(pageData.article_context || '');
  const [IsLoading, setIsLoading] = useState(false);
  const [serpActive, setSerpActive] = useState(false);
  const [modalText, setModalText] = useState("");
  const [requestModalActive, setRequestModalActive] = useState(false);
  const failAlertRef = useRef<any>(null);
  const successAlertRef = useRef<any>(null);
  const [isCreativeActive, setIsCreativeActive] = useState(true);
  const [isVerified, _] = useState(pageData.verified);
  const [currentKeyword, setCurrentKeyword] = useState('');
  // const [showSurvey, setShowSurvey] = useState(false);
  const context = useContext(SurveyContext);
  if (!context) {
    // Handle the case where the context is not provided
    throw new Error("CreateArt must be used within a SurveyProvider");
  }
  const { showSurvey } = context;

  
  //Creative Logic Implemented on keyword Click
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    failAlertRef.current?.close();
    // Check if the keyword is empty
    if (!keyword) {
      return; 
    }
     // Prevent searching if the keyword hasn't changed
     if (keyword === currentKeyword) {
      return; // No new search
    }

    setIsLoading(true);
    setCreativetitles([]);
    setSerpTitles([]);

    try {
      const response = await makeApiRequest(
        `/api/frontend/fetch-creative-title-data/`, // Update with the correct API path
        'post',
        {
          keyword,
          max_titles: maxTitles,
        }
      );
  
      const data = response.data;
      if (data.success) {
        setCreativetitles(data.titles);
        setCurrentKeyword(keyword); // Update the current keyword
        setIsCreativeActive(true); // Activate Creative Ideas button
        setSerpActive(false); // Deactivate SERP Ideas
      } else {
        failAlertRef.current?.show('Failed to fetch titles from Groq-based API.');
      }
    } catch (err) {
      failAlertRef.current?.show('An error occurred while fetching Groq titles.');
    } finally {
      setIsLoading(false); 
    }
  }

  // Function to handle suggestion click
  const handleSuggestionClick = (title) => {
    setArticleTitle(title);
  };

  //Serp Button Handle Logic
  const handleSerpButtonClick = async () => {
    if (!keyword) {
      return; 
    }
    // Only fetch titles if they haven't been generated yet
    if (serpTitles.length > 0) {
      setIsCreativeActive(false);
      setSerpActive(true);
      return; 
    }
    setSerpActive(true); // Toggle the active state
    setIsCreativeActive(false); // Deactivate Creative Ideas
    setIsLoading(true);

    // Serper Logic Implemented here
    try {
      // Use makeApiRequest to call your backend API
      const response = await makeApiRequest(
        `/api/frontend/fetch-serper-title-data/`,
        'post',
        {
          keyword: keyword,
          location: location,
          max_titles: maxTitles,
        }
      );

      // Process the API response
      const data = response.data;
      if (data.success) {
        setSerpTitles(data.titles);
      } else {
        failAlertRef.current?.show(data.error || 'Something went wrong');
      }
    } catch (err) {
      if (err instanceof APIError) {
        if (err.statusCode === 401) {
          failAlertRef.current?.show('Unauthorized. Please log in again.');
        } else {
          failAlertRef.current?.show('An error occurred while fetching titles.');
        }
      } else {
        failAlertRef.current?.show('An error occurred while fetching titles.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateArticle = async () => {
    if (!articleTitle || !keyword) {
      return;
    }

    if (keyword.length > 300) {
      failAlertRef.current?.show('Keyword is too long. Please enter a shorter keyword.');
      return;
    }

    // Remove any existing error message
    failAlertRef.current?.close();

    try {
      const response = await makeApiRequest(
        '/api/frontend/new-article-data/',
        'post',
        {
          keyword: keyword,
          title: articleTitle,
          serp_titles: serpTitles,
          creative_titles: creativeTitles,
          article_context: articleContext
        }
      );

      // Assuming the response structure contains the necessary fields
      const data = response.data;
      if (data.success) { 
        console.log('Generating article....');
        generateArticleHandler(data.article_uid);
      } else {
        failAlertRef.current?.show(data.error || 'Failed to generate article.');
      }
    } catch (err) {
      if (err instanceof APIError) {
        if (err.statusCode === 401) {
          failAlertRef.current?.show('Unauthorized. Please log in again.');
        } else {
          failAlertRef.current?.show('An error occurred while generating the article: ' + (err.responseData.message || 'Unknown error'));
        }
      } else {
        failAlertRef.current?.show('An unexpected error occurred: ' + ('Unknown error'));
      }
    }
  };

  //Logic to Generate Article
  const generateArticle = useMutation(generateV2ArticleMutation);
  const navigate = useNavigate();
  function generateArticleHandler(articleUID: string) {
    setModalText("Processing request. Please wait...");
    setRequestModalActive(true);
    generateArticle.mutate(articleUID, {
      onSuccess: (data) => {
        setRequestModalActive(false);
        let responseData = (data as any)["data"];

        if (responseData["status"] === "sent_for_processing") {
          navigate(`/articles/edit/${articleUID}/`);
        } else if (responseData["status"] === "rejected") {
          if (responseData["reason"] === "max_limit_reached") {
            failAlertRef.current?.show("Article generation request failed. " +
              "You have reached your max article generation limit for the month.");
          } else {
            failAlertRef.current?.show(`Article generation request failed. Error ID: ${responseData["reason"]}`);
          }
        } else {
          failAlertRef.current?.show(
            `Article generation request returned unknown status ${responseData["status"]}. Please contact us if there's any issue.`
          );
        }
      },
      onError: (error: Error) => {
        console.error(error);
        setRequestModalActive(false);
        failAlertRef.current?.show(`Article generation request failed. Please try again later`)
        setTimeout(() => {
          failAlertRef.current?.close();
        }, 5000);
      }
    });
  }
  const handleCreativeIdeas = async () => {
    if (!keyword) {
      return; 
    }
    // Only fetch titles if they haven't been generated yet
    if (creativeTitles.length > 0) {
      setIsCreativeActive(true);
      setSerpActive(false);
      return; 
    }
  };

  
  
  return (
    <>
    {showSurvey && <Survey />}
    <div className="ca-container">
      <div className="ca-header">
        <h2 className={"is-size-4"} style={{ fontWeight: "800" }}>Create Article</h2>
      </div>

    {/* Center-aligned checkboxes in two rows and three columns */}
    <div className="ca-checkbox-row">
      <div className="ca-checkbox-group">
        <div className="ca-checkbox-item">
          <span className="elementor-icon-list-icon" id="fct">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
              <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
            </svg>
          </span>
          <span className="ca-checkbox-text">Google Compliant</span>
        </div>
        <div className="ca-checkbox-item">
          <span className="elementor-icon-list-icon" id="fct">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
              <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
            </svg>
          </span>
          <span className="ca-checkbox-text">No Plagiarism</span>
        </div>
      </div>
      <div className="ca-checkbox-group">
        <div className="ca-checkbox-item">
          <span className="elementor-icon-list-icon" id="fct">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
              <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
            </svg>
          </span>
          <span className="ca-checkbox-text">Optimized for Ranking</span>
        </div>
        <div className="ca-checkbox-item">
          <span className="elementor-icon-list-icon" id="fct">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
              <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
            </svg>
          </span>
          <span className="ca-checkbox-text">Auto-Publish Blogs</span>
        </div>
      </div>
      <div className="ca-checkbox-group">
        <div className="ca-checkbox-item">
          <span className="elementor-icon-list-icon" id="fct">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
              <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
            </svg>
          </span>
          <span className="ca-checkbox-text">Human-Like Content</span>
        </div>
        <div className="ca-checkbox-item">
          <span className="elementor-icon-list-icon" id="fct">
            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path opacity="0.5" d="M21.8385 10.9993C21.8385 16.9824 16.9882 21.8327 11.0052 21.8327C5.02212 21.8327 0.171875 16.9824 0.171875 10.9993C0.171875 5.01626 5.02212 0.166016 11.0052 0.166016C16.9882 0.166016 21.8385 5.01626 21.8385 10.9993Z" fill="#1E9156"></path>
              <path d="M15.3688 7.71649C15.6861 8.03379 15.6861 8.54824 15.3688 8.8655L9.95209 14.2822C9.63479 14.5995 9.12042 14.5995 8.80308 14.2822L6.63641 12.1155C6.31911 11.7982 6.31911 11.2838 6.63641 10.9665C6.95371 10.6492 7.46816 10.6492 7.78546 10.9665L9.3776 12.5586L11.7986 10.1376L14.2198 7.71649C14.5371 7.39919 15.0515 7.39919 15.3688 7.71649Z" fill="#0B7944"></path>
            </svg>
          </span>
          <span className="ca-checkbox-text">Factual Content</span>
        </div>
      </div>
    </div>

      {/* Flexbox for input fields and suggestions */}
      <div className="ca-content-row">
        <div className="ca-form-column">
          <form onSubmit={handleSubmit} style={{ marginTop: '-20px' }}>
            <label className="ca-label">Keyword</label>
            <input
              className="ca-input"
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Enter keyword"
              onBlur={handleSubmit} // Automatically fetch titles when focus is lost
              style={{ marginTop: '3px', textAlign: 'start' }}
              required
            />
          </form>

          <label className="ca-label">Article Title</label>
          <input
            className="ca-input"
            type="text"
            value={articleTitle}
            placeholder="Enter Article Title or choose from the right widget"
            onChange={(e) => setArticleTitle(e.target.value)}
            style={{textAlign: 'start'}}
            required
          />

          <label className="ca-label">Instructions & Context (Optional)</label>
          <textarea
            rows={10}
            className="ca-input"
            value={articleContext}
            placeholder="Include MyCompany.com at the top of the list. Also talk more about how MyCompany.com helps with This, This & That"
            onChange={(e) => setArticleContext(e.target.value)}
            style={{textAlign: 'start'}}
            required
          />

          <div className="Generate_button_large" style={{ position: 'relative', display: 'inline-block'}}>
            <div data-tooltip-id="buttonTip" data-tooltip-content="Verify email to create article">
              <GenericButton
                text={"Generate Article ➜"}
                type={"primary"}
                width={"219px"}
                height={"40px"}
                left={"7px"}
                outlined={true}
                disable={!isVerified}
                additionalClassList={["is-small", "more-rounded-borders"]}
                clickHandler={handleGenerateArticle}
                style={{ fontSize: "1rem" }}
              />
            </div>
            {!isVerified && (
              <Tooltip id="buttonTip" place="bottom" />
            )}
          </div>
        </div>

        <div className="ca-suggestion-box">
          <h3 className="ca-suggestion-title">Article Title Suggestions based on keyword</h3>
          <div className="ca-suggestions">
            <button className={`ca-suggestion-button ${isCreativeActive ? 'active' : ''}`} onClick={handleCreativeIdeas}>
              Creative Ideas
              <span className="circle-question"
                data-tooltip-id="style_button"
                data-tooltip-content="AI brainstormed, Creative Titles designed to help you stand out!">?</span>
            </button>
            <button className={`ca-suggestion-button ${serpActive ? 'active' : ''}`} onClick={handleSerpButtonClick}>
              SERP Based Ideas
              <span className="circle-question" data-tooltip-id="style_button"
                data-tooltip-content="Top-ranking Titles inspired by SERP results!">?</span></button>
          </div>
          <ul className="ca-suggestion-list">
            {IsLoading ? (
              <li className="ca-suggestion-item">Generating Titles, please wait...</li>
            ) : isCreativeActive && creativeTitles.length > 0 ? (
              // Render Creative Ideas when active
              creativeTitles.map((title, index) => (
                <li key={index} className="ca-suggestion-item"
                  data-tooltip-id="tooltip" 
                  data-tooltip-content={keyword ? "Click to use title" : "Please enter a keyword"}
                  onClick={() => {
                    if (keyword) {
                      handleSuggestionClick(title);
                    }
                  }}>
                  {title}
                </li>
              ))
            ) : serpActive && serpTitles.length > 0 ? (
              // Render SERP-based Ideas when active
              serpTitles.map((title, index) => (
                <li key={index} className="ca-suggestion-item"
                  data-tooltip-id="tooltip" 
                  data-tooltip-content={keyword ? "Click to use title" : "Please enter a keyword"}
                  onClick={() => {
                    if (keyword) {
                      handleSuggestionClick(title);
                    }
                  }}>
                  {title}
                </li>
              ))
            ) : (
              <h3 className="ca-suggestion-title"
                style={{ fontWeight: '500', marginTop: '50px', fontFamily: 'Inter', fontStyle: 'italic',fontSize:'18px' }}
              >
                Enter Keyword to get Title Suggestions</h3>
            )}
          </ul>
          {/* React Tooltip Component */}
          <Tooltip id="tooltip" place="bottom" />
          <Tooltip id="style_button" place="bottom" />
        </div>
      </div>
      <div className="Generate_button">
            <div data-tooltip-id="buttonTip" data-tooltip-content="Verify email to create article">
              <GenericButton
                text={"Generate Article ➜"}
                type={"primary"}
                width={"219px"}
                height={"40px"}
                left={"7px"}
                outlined={true}
                disable={!isVerified}
                additionalClassList={["is-small", "more-rounded-borders"]}
                clickHandler={handleGenerateArticle}
                style={{ fontSize: "1rem" }}
              />
            </div>
            {!isVerified && (
              <Tooltip id="buttonTip" place="bottom" />
            )}
      </div>
      
      {/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
      <AbunModal active={requestModalActive}
        headerText={""}
        closeable={false}
        hideModal={() => {
          setRequestModalActive(false)
        }}>
        <div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
          <img className={"image"} src={loadingIcon} alt="loading" />
        </div>
        <p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
      </AbunModal>

      <ErrorAlert ref={failAlertRef} />
      
      {showConnectWebsiteModal && (
          <ConnectWebsite 
              setShowConnectWebsiteModal={setShowConnectWebsiteModal}
              failAlertRef={failAlertRef}
              successAlertRef={successAlertRef}
              navigateOrReload="reload"
          />
      )}
    </div>
    </>
  );
};

export default CreateArticle;