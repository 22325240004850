import "./TextArea.scss";
import {CSSProperties} from "react";

interface TextAreaProps {
	value: string
	placeholder?: string
	className?: string
	style?: CSSProperties
	rows?: number
	onChange: (value: string) => void
}

export default function TextArea(props: TextAreaProps) {
	return (
		<textarea value={props.value}
							placeholder={props.placeholder || ""}
							className={`textarea ${props.className ? props.className : ""}`}
							style={props.style}
							rows={props.rows}
							onChange={(e) => {props.onChange(e.target.value)}} />
	)
}
