/* eslint-disable jsx-a11y/anchor-is-valid */
import {useRef} from "react";
import abunLogo from "../../assets/images/branding/abun_blue_text_logo.png";
import {Link} from "react-router-dom";
import {pageURL} from "../../pages/routes";

import "./AuthPageNavbar.scss";

interface AuthPagenavbarProps {
	signupPlanSelection?: boolean
}

export default function AuthPageNavbar(props: AuthPagenavbarProps) {
	// -------------------------- REFS --------------------------
	const navbarHamburgerRef = useRef<HTMLAnchorElement>(null);

	// -------------------------- FUNCTIONS --------------------------
	function toggleNavbarHamburgerMenu() {
		const targetID: string | undefined = navbarHamburgerRef.current?.dataset.target;
		if (targetID) {
			const target: HTMLElement | null = document.querySelector(`#${targetID}`);
			if (target) {
				navbarHamburgerRef.current?.classList.toggle('is-active');
				target.classList.toggle('is-active');
			} else {
				console.error("Could not find navbar hamburger target element.");
			}
		} else {
			console.error("Could not find navbar hamburger target id.");
		}
	}

	// -------------------------- MAIN RENDER CODE --------------------------
	return <nav className="navbar is-spaced is-white" role="navigation" aria-label="main navigation">
		<div className="navbar-brand">
			<a className="navbar-item no-hover-background-color" href="https://abun.com">
				<img src={abunLogo} width="128" height="auto" alt="Abun Logo"/>
			</a>
			<a role="button"
				 className="navbar-burger"
				 ref={navbarHamburgerRef}
				 aria-label="menu"
				 aria-expanded="false"
				 data-target="abun-auth-page-navbar-menu" onClick={toggleNavbarHamburgerMenu}>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
				<span aria-hidden="true"></span>
			</a>
		</div>
		<div id="abun-auth-page-navbar-menu" className="navbar-menu">
			<div className="navbar-end">
				{props.signupPlanSelection ?
					<h3 className={"font-secondary has-text-weight-bold is-size-3"}>
						Guaranteed SEO organic traffic for your website.
					</h3>
					:
					<>
						<Link to={pageURL["login"]} className="navbar-item navbar-item-font navbar-item-spacing">Login</Link>
						<Link to={pageURL["signup"]} className="navbar-item navbar-item-font navbar-item-spacing">Signup</Link>
					</>
				}
			</div>
		</div>
	</nav>;
}
