/* eslint-disable react-hooks/exhaustive-deps */
import "./CompetitorResearch.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import { generateCompetitorKeywords, getCompResearchData, retryFn, addCompetitors, removeCompetitors } from "../../utils/api";
import LoadingError from "../../components/LoadingError/LoadingError";
import LoadingData from "../../components/LoadingData/LoadingData";
import { useEffect, useRef, useState, useMemo, useContext } from "react";
import AbunTable from "../../components/AbunTable/AbunTable";
import { ColumnDef, createColumnHelper, RowModel, RowData } from "@tanstack/react-table";
import Card from "../../components/Card/Card";
import AbunButton from "../../components/AbunButton/AbunButton";
import TextArea from "../../components/TextArea/TextArea";
import Icon from "../../components/Icon/Icon";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import { pageURL } from "../routes";
import { Link, useNavigate } from "react-router-dom";
import loadingIcon from "../../assets/images/loadingIcon.webp"
import AbunModal from "../../components/AbunModal/AbunModal";
import { getDefaultCompetitorLogo } from "../../utils/misc";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import countries from "../../utils/constants/CountriesforSerp";
import EarthFlag from "../../assets/images/earth-flag.png"
import { SurveyContext } from "../../pages/Login/SurveyContext";
import Survey from "../../components/Survey/Survey";

interface CompetitorData {
	domain: string
	keywords_generated: boolean
	keyword_project_id?: string
	organic_traffic?: string | null
	organic_keywords?: string | null
	domain_authority?: string | null
	total_backlinks?: string | null
	follow?: string | null
	no_follow?: string | null
	referring_domains?: string | null
}

interface TableRow {
	logo: string
	domain: string
}

interface CountryType {
	location_code: number;
	location_name: string;
	country_iso_code: string;
	suggested?: boolean;
}


export default function CompetitorResearch() {
	// ---------------------- NON-STATE CONSTANTS ----------------------
	const pageSizes = [30, 100, 500]

	// ---------------------- STATES ----------------------
	const [
		competitorData,
		setCompetitorData
	] = useState<CompetitorData[]>([]);

	const [
		generatingKeywordsModalActive,
		setGeneratingKeywordsModalActive
	] = useState(false);

	const [
		selectedRows,
		setSelectedRows
	] = useState<RowModel<RowData>>();

	const [
		showAddCompetitorsModal,
		setShowAddCompetitorsModal
	] = useState<boolean>(false);

	const [
		domainTextArea,
		setDomainTextArea
	] = useState<string>("");

	const [
		bulkDeleteConfirmationModalActive,
		setBulkDeleteConfirmationModalActive
	] = useState<boolean>(false);

	const [
		disableAddCompetitorsButton,
		setDisableAddCompetitorsButton
	] = useState<boolean>(false);

	const [
		showAddLocationsModal,
		setShowAddLocationsModal
	] = useState<boolean>(false);

	const [
		selectedCompetitor,
		setSelectedCompetitor
	] = useState<string>("");

	const [
		selectedLocation,
		setSelectedLocation
	] = useState<CountryType>({
		"location_code": 1,
		"location_name": "Global",
		"country_iso_code": "ZZ"
	});
	const context = useContext(SurveyContext);
	
	// ----------------------- REFS -----------------------
	const failAlertRef = useRef<any>(null);
	const successAlertRef = useRef<any>(null);
	const navigate = useNavigate();

	// ---------------------- QUERIES ----------------------
	const competitorsFetch = useQuery({
		queryKey: ['getCompResearchData'],
		queryFn: getCompResearchData,
		refetchOnWindowFocus: false,
		retry: retryFn
	});

	// ---------------------- MUTATIONS ----------------------
	const generateKeywords = useMutation({
		mutationKey: ['generateCompetitorKeywords'],
		mutationFn: generateCompetitorKeywords,
		cacheTime: 0,
		onSuccess: () => {
			failAlertRef.current?.close();
			setGeneratingKeywordsModalActive(false);
			competitorsFetch.refetch().then();
		},
		onError: (error: Error) => {
			setGeneratingKeywordsModalActive(false);
			failAlertRef.current?.show(error.message);
			setTimeout(() => {
				failAlertRef.current?.close();
			}, 5000);
		}
	});

	const removeCompetitorsMut = useMutation({
		mutationKey: ['removeCompetitors'],
		mutationFn: removeCompetitors,
		cacheTime: 0,
		retry: retryFn,
		onError: (error) => {
			console.error(error);
			successAlertRef.current?.close();
			failAlertRef.current?.show("Server Error. Please try again in some time.");
		}
	});

	const addCompetitorsMut = useMutation({
		mutationKey: ['addCompetitors'],
		mutationFn: addCompetitors,
		cacheTime: 0,
		retry: retryFn,
		onError: (error) => {
			console.error(error);
			successAlertRef.current?.close();
			failAlertRef.current?.show("Server Error. Please try again in some time.");
		}
	})

	// ---------------------- EFFECTS ----------------------
	useEffect(() => {
		if (competitorsFetch.data) {
			const backBtn = document.querySelector(".back-btn.steal-comp-keywords");
			if (backBtn && (backBtn.parentNode?.firstChild as HTMLElement)?.tagName === "ARTICLE") {
				backBtn.classList.add("warning-is-present");
			}
			if (competitorsFetch.data.data === "No website found") {
				setDisableAddCompetitorsButton(true);
			} else {
				setCompetitorData(competitorsFetch.data['data']);
				if (competitorsFetch.data['data'].length > 0 && competitorsFetch.data['data'][0]["content_plan_generation_status"] === "done") {
					setDisableAddCompetitorsButton(false);
				}
			}
		}
	}, [competitorsFetch.data]);

	if (!context) {
		throw new Error("CreateArt must be used within a SurveyProvider");
	}
	const { showSurvey } = context;

	// ------------------------FUNCTIONS -----------------------------

	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	function addCompetitorsHandler() {
		let domains: Array<string> = domainTextArea.split("\n");
		if (domains.length > 0) {
			domains = domains.map(value => value.toLowerCase().trim());
			addCompetitorsMut.mutate({ domains: domains }, {
				onSuccess: () => {
					// Close modal
					setShowAddCompetitorsModal(false);
					setDomainTextArea("");

					// Modify (table) data
					competitorsFetch.refetch().then();

					failAlertRef.current?.close();
					successAlertRef.current?.show("Competitor(s) have been added successfully!");
				}
			})
		}
	}

	function removeCompetitorsHandler(domains: Array<string>) {
		removeCompetitorsMut.mutate({ domains: domains }, {
			onSuccess: () => {
				// Uncheck checkboxes
				selectedRows?.rows.forEach(row => {
					row.toggleSelected()
				});

				// update table data
				const updatedCompetitors = competitorData.filter((competitor) => !domains.includes(competitor.domain));
				setCompetitorData(updatedCompetitors);

				failAlertRef.current?.close();
				successAlertRef.current?.show("Selected competitor(s) have been removed successfully!");
			}
		});
	}

	// ---------------------- TABLE DATA ----------------------
	const columnHelper = createColumnHelper<CompetitorData>();

	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: CompetitorData) => row.domain, {
			id: 'domain',
			header: "Competitor",
			cell: useMemo(() => info => {
				if (info.getValue() === null) {
					return "---";
				} else {
					let cellValue = info.getValue();
					if (cellValue.length > 25) {
						cellValue = cellValue.substring(0, 24) + "...";
					}
					return <div className={"is-flex is-align-items-center"}>
						{/* <div className={"comp-research-logo-container is-inline-block mr-3"}>
							<img
								src={`${process.env['REACT_APP_LOGO_URL']}/${info.row.original.domain}`}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = getDefaultCompetitorLogo();
								}}
								className={"comp-research-table-logo"}
								alt={"logo"}
							/>
						</div> */}
						<div className={"is-inline-block"}>
							{cellValue}
						</div>
					</div>;
				}
			}, []),
			enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'generate',
			header: "Generate Competitor Keywords Rankings",
			cell: props => {
				if (props.row.original.keywords_generated && props.row.original.keyword_project_id) {
					return (<div className="ml-6">
						<Link to={
							pageURL['keywordResearch'] + "?keywordProjectId=" + props.row.original.keyword_project_id
						}
							className={"button ml-6 is-primary is-small comp-research-table-button"}>
							View Keywords
						</Link>
					</div>
					)

				} else {
					return (<div className="ml-6">
						<AbunButton type={"success"}
							className={"is-outlined ml-6 is-small comp-research-table-button"}
							disabled={generateKeywords.isLoading}
							clickHandler={() => {
								setShowAddLocationsModal(true);
								setSelectedCompetitor(props.row.original.domain);
							}}>
							Generate Keywords
						</AbunButton>
					</div>
					)
				}
			},
			meta: {
				align: "left"
			}
		})
	]

	// ==============================================================
	// ---------------------- MAIN RENDER CODE ----------------------
	// ==============================================================

	if (competitorsFetch.isFetching) return <LoadingData />

	if (competitorsFetch.isError) return <LoadingError />

	return (
		<>
			{showSurvey && <Survey />}
			<svg className={"back-btn steal-comp-keywords"} onClick={() => navigate(pageURL['keywordResearch'])} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
				<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
			</svg>
			<AbunModal active={generatingKeywordsModalActive}
				headerText={""}
				closeable={false} hideModal={() => setGeneratingKeywordsModalActive(false)}>
				<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
					<img className={"image"} src={loadingIcon} alt="loading" />
				</div>
				<p className={"is-size-4 has-text-centered mb-4"}>
					Generating keywords. Please wait until it's done.
				</p>
			</AbunModal>
			<AbunModal active={showAddCompetitorsModal}
				headerText={""}
				closeable={true}
				hideModal={() => setShowAddCompetitorsModal(false)}>
				<h4 className={"is-size-4 mt-2 has-text-centered font-secondary has-text-primary has-text-weight-bold"}>
					Enter Competitors
				</h4>
				<p className={"has-text-centered"}>
					You can add both full URLs (ex: <b>https://example.com/blog/</b>) or only registered
					domain (ex: <b>example.com</b>). Invalid values will be ignored.
				</p>
				<TextArea value={domainTextArea}
					className={"mt-4"}
					placeholder={"Enter each competitor on a new line..."}
					rows={15}
					onChange={setDomainTextArea} />
				<AbunButton type={"success"}
					className={"mt-5 is-block ml-auto mr-auto"}
					disabled={!domainTextArea.length || addCompetitorsMut.isLoading}
					clickHandler={addCompetitorsHandler}>
					<Icon iconName={"floppy-disk"} additionalClasses={["icon-white"]} />
					&nbsp;&nbsp;{addCompetitorsMut.isLoading ? "Saving..." : "Save Competitors"}
				</AbunButton>
			</AbunModal>
			<Card className="w-100">
				<AbunTable tableContentName={"Competitors"}
					tableData={competitorData}
					tableName="Steal Competitor Keywords"
					columnDefs={columnDefs}
					pageSizes={pageSizes}
					initialPageSize={pageSizes[2]}
					noDataText={"No Competitors Found"}
					searchboxPlaceholderText={"Search Competitors..."}
					id="competitor-research-table"
					buttons={[
						{
							text: "Add Competitors",
							type: "success",
							isDisabled: disableAddCompetitorsButton,
							clickHandler: () => setShowAddCompetitorsModal(true),
							extraClassName: "is-small is-justify-content-space-between",
							iconName: "plus",
							iconClasses: ["icon-white"]
						}
					]}
				/>
			</Card>
			<div className={"blur-background " + (bulkDeleteConfirmationModalActive ? "" : "hidden")}>
				<div className={"confirmation-card w-100 mt-4"}>
					<button className={"delete is-pulled-right"}
						onClick={() => {
							setBulkDeleteConfirmationModalActive(false);
							selectedRows?.rows.forEach(row => {
								row.toggleSelected()
							});
						}} />
					<div className={"confirmation-card-content w-100"}>
						<h3 className={"is-size-4 has-text-centered"}>
							Are you sure you want to remove the selected competitors?
						</h3>
						<div className={"is-flex is-justify-content-center"}>
							<AbunButton type={"primary"}
								className={"mt-5 is-block ml-auto mr-auto go-back-button"}
								clickHandler={() => {
									setBulkDeleteConfirmationModalActive(false);
								}}>
								&nbsp;&nbsp;Go Back
							</AbunButton>
							<AbunButton type={"danger"}
								className={"mt-5 is-block ml-auto mr-auto"}
								clickHandler={() => {
									// Fetch the row's domain values and call delete function.
									let domains = selectedRows?.rows.map(row => {
										return (row.original as TableRow).domain;
									});
									if (domains) {
										removeCompetitorsHandler(domains as Array<string>);
										setSelectedRows(undefined);
										setBulkDeleteConfirmationModalActive(false);
									}
								}}>
								<Icon iconName={"trash"} additionalClasses={["icon-white"]} />
								&nbsp;&nbsp;Yes, Remove
							</AbunButton>
						</div>
					</div>
				</div>
			</div>
			<div className={"blur-background " + (showAddLocationsModal ? "" : "hidden")}>
				<div className={"confirmation-card w-100 mt-4"}>
					<button className={"delete is-pulled-right"}
						onClick={() => {
							setShowAddLocationsModal(false);
							selectedRows?.rows.forEach(row => {
								row.toggleSelected()
							});
						}} />
					<div className={"confirmation-card-content w-100"}>
						<h3 className={"is-size-5 has-text-centered"}>
							Select the location you want to get keywords volume for:
						</h3>
						<div className={"comp-research-location-select"}>
							<div className={"comp-info"}>
								<img
									src={`${process.env['REACT_APP_LOGO_URL']}/${selectedCompetitor}`}
									onError={({ currentTarget }) => {
										currentTarget.onerror = null;
										currentTarget.src = getDefaultCompetitorLogo();
									}}
									className={"comp-logo"}
									alt={"logo"}
								/>
								<h4 className={"is-size-4 has-text-centered font-secondary"}>
									{selectedCompetitor}
								</h4>
							</div>
							<div className={"form-group location-select"}>
								<Autocomplete
									id="country-select-demo"
									sx={{ width: 300 }}
									options={countries}
									value={selectedLocation}
									autoHighlight
									getOptionLabel={(option) => option.country_iso_code !== "ZZ" ? `${option.location_name} (${option.country_iso_code})` : option.location_name}
									isOptionEqualToValue={(option, value) => option.location_code === value.location_code}
									renderOption={(props, option) => (
										<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
											<img
												loading="lazy"
												width="20"
												srcSet={option.country_iso_code !== "ZZ" ? `https://flagcdn.com/w40/${option.country_iso_code.toLowerCase()}.png 2x` : EarthFlag}
												src={option.country_iso_code !== "ZZ" ? `https://flagcdn.com/w20/${option.country_iso_code.toLowerCase()}.png` : EarthFlag}
												alt=""
											/>
											{option.location_name} ({option.country_iso_code})
										</Box>
									)}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Location"
											inputProps={{
												...params.inputProps,
												// disable autocomplete and autofill and suggestion
												autoComplete: 'off',
											}}
										/>
									)}
									onChange={(event, option) => {
										if (option) {
											setSelectedLocation(option);
										}
									}}
								/>
							</div>
							<AbunButton type={"primary"}
								className={"mt-5 is-block ml-auto mr-auto"}
								clickHandler={() => {
									if (selectedCompetitor) {
										generateKeywords.mutate({
											domain: selectedCompetitor,
											selectedLocation: selectedLocation,
										});
										setGeneratingKeywordsModalActive(true);
										setShowAddLocationsModal(false);
									}
								}}>Proceed
							</AbunButton>
						</div>
					</div>
				</div>
			</div>
			<ErrorAlert ref={failAlertRef} />
		</>
	)
}
