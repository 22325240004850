import React, {useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import WebsiteDomain from "./WebsiteDomain";
import WebsiteTitle from "./WebsiteTitle";
import WebsiteICP from "./WebsiteICP";
import WebsiteCompetitors from "./WebsiteCompetitors";

import abunLogo from "../../assets/images/branding/abun_blue_text_logo.png";

import "./ConnectWebsite.scss";
import {useQuery} from "@tanstack/react-query";
import {connectWebsiteQuery} from "../../utils/api";
import {pageURL} from "../routes";
import ConnectWebsiteLoading from "./ConnectWebsiteLoading";

interface WebsiteData {
	domain: string
	protocol: string
	blog: string
	title: string
	description: string
	industry: string
	icp: string
	language: string
	competitors: Array<string>
	generate_content_plan: boolean
}

export default function ConnectWebsite() {
	// -------------------- STATES --------------------
	const [stage, setStage] = useState(0);  // start from first stage
	const [called, setCalled] = useState(false);

	// -------------------- CONSTANTS --------------------
	const navigate = useNavigate();
	const stages = [
		<WebsiteDomain getWebsiteData={getWebsiteData} updateWebsiteData={updateWebsiteData} nextStage={nextStage} getWebsiteTempData={getWebsiteTempData} updateWebsiteTempData={updateWebsiteTempData} />,  // domain & blog
		<WebsiteTitle getWebsiteData={getWebsiteData} updateWebsiteData={updateWebsiteData} nextStage={nextStage} getWebsiteTempData={getWebsiteTempData} updateWebsiteTempData={updateWebsiteTempData} setStage={setStage} />,  // title & description
		<WebsiteICP getWebsiteData={getWebsiteData} updateWebsiteData={updateWebsiteData} nextStage={nextStage} getWebsiteTempData={getWebsiteTempData} updateWebsiteTempData={updateWebsiteTempData} setStage={setStage} />,  // icp text & industry
		<WebsiteCompetitors getWebsiteData={getWebsiteData} updateWebsiteData={updateWebsiteData} nextStage={nextStage} setStage={setStage} />,  // competitors
	];

	// -------------------- REFS --------------------
	const connectWebsiteData = useRef<WebsiteData>({
		domain: '',
		protocol: '',
		blog: '',
		title: '',
		description: '',
		industry: '',
		icp: '',
		language: '',
		competitors: [],
		generate_content_plan: false,
	});

	// Temporary Website Data this used when user go back to previous steps
	const websiteTempData = useRef<WebsiteData>({
		domain: '',
		protocol: '',
		blog: '',
		title: '',
		description: '',
		industry: '',
		icp: '',
		language: '',
		competitors: [],
		generate_content_plan: false,
	});

	const {
		data,
		refetch,
		status,
		fetchStatus
	} = useQuery(connectWebsiteQuery(connectWebsiteData.current));

	// -------------------- EFFECTS --------------------
	useEffect(() => {
		document.title = "Add Website | Abun"
	}, []);

	useEffect(() => {
		if (status === 'success' && called) {
			// take users to  competitorResearch page
			navigate(pageURL['keywordResearch']);
		}
	}, [called, navigate, status]);

	function getWebsiteData(): WebsiteData {
		return connectWebsiteData.current;
	}

	function updateWebsiteData(key: keyof WebsiteData, value: any) {
		if (key !== 'generate_content_plan') connectWebsiteData.current[key] = value;
	}

	function getWebsiteTempData(): WebsiteData {
		return websiteTempData.current;
	}
	function updateWebsiteTempData(key: keyof WebsiteData, value: any) {
		if (key !== 'generate_content_plan') websiteTempData.current[key] = value;
	}

	/**
	 * Use to move to next stage.
	 */
	function nextStage() {
		let newStage = stage + 1;
		if (newStage < stages.length) {
			setStage(stage + 1);
		} else {
			// Last stage is done. Time to call connect website api.
			if (connectWebsiteData.current.competitors.length > 0 && !called) {
				refetch().then();
				setCalled(true);
			}
		}
	}


	return (
		<>
			<div className="container is-flex is-flex-direction-column is-align-items-center" style={{maxHeight: "100vh", overflow: "none"}}>
				<img src={abunLogo} className="connectwebsite-logo" alt="Abun Logo"/>
				{fetchStatus !== 'idle' && stage !== stages.length-1 ? <ConnectWebsiteLoading text={"Finishing setup. Just a moment..."}/> : stages[stage]}
				<Link className={`return-to-dahshboard`} to={pageURL['keywordResearch']}>Return to Keyword Research</Link>
			</div>
		</>
	)
}
