import React, { createContext, useState, useEffect, ReactNode } from "react";
import { getLoggedInPageData } from "../routes"
import { KEYWORD_RESEARCH_PAGE_API } from "../../utils/api";

interface SurveyContextType {
    showSurvey: boolean;
    setShowSurvey: React.Dispatch<React.SetStateAction<boolean>>;
    surveyCompleted: boolean;
    setSurveyCompleted: React.Dispatch<React.SetStateAction<boolean>>;
}

// Create the context with a default value
export const SurveyContext = createContext<SurveyContextType | undefined>(undefined);

// Define the props for the provider component
interface SurveyProviderProps {
    children: React.ReactNode;
    disableSurvey?: boolean;
}

export const SurveyProvider: React.FC<SurveyProviderProps> = ({ children, disableSurvey = false }) => {
    const [showSurvey, setShowSurvey] = useState<boolean>(false);
    const [isSurveyCompleted, setSurveyCompleted] = useState<boolean>(true);

    // Fetch survey completion status from the API
    useEffect(() => {
        const fetchSurveyData = async () => {
            try {
                const data = await getLoggedInPageData(KEYWORD_RESEARCH_PAGE_API);
                console.log(data)
                console.log(data.survey_completed)
                setSurveyCompleted(data.survey_completed); // Assuming `survey_completed` is part of the fetched data
            } catch (error) {
                console.error("Failed to fetch survey data:", error);
            }
        };

        fetchSurveyData();
    }, []);

    useEffect(() => {
        // If the survey is completed, skip the logic to show the survey
        console.log("isSurveyCompleted", isSurveyCompleted)
        if (isSurveyCompleted) {
            return;
        }

        const firstVisitTime = localStorage.getItem("firstVisitTime");

        if (!firstVisitTime) {
            const now = Date.now();
            localStorage.setItem("firstVisitTime", now.toString());
        }

        const elapsedTime = Date.now() - Number(localStorage.getItem("firstVisitTime"));
        const remainingTime = 30 * 60 * 1000 - elapsedTime;

        if (remainingTime <= 0) {
            setShowSurvey(true);
        } else {
            const timer = setTimeout(() => {
                setShowSurvey(true);
            }, remainingTime);

            return () => clearTimeout(timer); // Clean up the timer
        }
    }, [isSurveyCompleted]);

    useEffect(() => {
        if (disableSurvey) {
            setShowSurvey(false);
        }
    }, [disableSurvey]);

    return (
        <SurveyContext.Provider value={{ showSurvey, setShowSurvey, surveyCompleted: isSurveyCompleted, setSurveyCompleted }}>
            {children}
        </SurveyContext.Provider>
    );
};