import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import ConnectWebsiteLoading from "./ConnectWebsiteLoading";
import { competitorsQuery } from "../../utils/api";

interface WebsiteCompetitorsProps {
	nextStage: () => void
	getWebsiteData: () => any
	updateWebsiteData: (key: any, value: any) => void
	setStage: (stage: number) => void
}

export default function WebsiteCompetitors(props: WebsiteCompetitorsProps) {
	const domain: string = props.getWebsiteData()['domain'];
	const protocol: string = props.getWebsiteData()['protocol'];

	// -------------------- STATES --------------------
	const [
		competitorDomains,
		setCompetitorDomains
	] = useState<Array<string>>([]);
	const [IsReqDone, setIsReqDone] = useState(false);

	// -------------------- QUERIES --------------------
	const { data, isLoading, isError, refetch } = useQuery(competitorsQuery(domain, protocol));


	// --------------- EFFECTS ---------------
	useEffect(() => {
		if (data) {
			setCompetitorDomains(data['data']);
			setIsReqDone(true);
		}
	}, [data]);

	// ==========================================================
	// -------------------- MAIN RENDER CODE --------------------
	// ==========================================================
	if (isLoading) {
		// call the api to get competitors
		refetch().then();
		return <ConnectWebsiteLoading text={"Processing. Please wait..."} />

	} else if (isError) {
		props.setStage(0);
		return <p>Oops! Something went wrong :(</p>

	} else if (competitorDomains.length > 0) {
		props.updateWebsiteData('competitors', competitorDomains);
		props.nextStage();
		return <ConnectWebsiteLoading text={"Saving competitors details..."} />
	} else {
		if (IsReqDone && competitorDomains.length === 0) {
			setCompetitorDomains(["abun.com"]);
			props.updateWebsiteData('competitors', ["abun.com"]);
			props.nextStage();
		}
		return <ConnectWebsiteLoading text={"Processing. Please wait..."} />
	}
}
