/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {titleDescQuery} from "../../utils/api";
import GenericButton from "../../components/GenericButton/GenericButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import ConnectWebsiteLoading from "./ConnectWebsiteLoading";

interface WebsiteTitleProps {
	nextStage: () => void
	getWebsiteData: () => any
	updateWebsiteData: (key: any, value: any) => void
	getWebsiteTempData: () => Object
	updateWebsiteTempData: (key: any, value: any) => void
	setStage: (stage: number) => void
}

interface TitleDescResult {
	title: string
	description: string
	language: string
}

export default function WebsiteTitle(props: WebsiteTitleProps) {
	// -------------------- NON STATE CONSTANTS --------------------
	const titleMinCharCount: number = 15;
	const descMinCharCount: number = 40;
	const protocol: string = props.getWebsiteData()['protocol'];
	const domain: string = props.getWebsiteData()['domain'];
	const tempFlag: string = props.getWebsiteTempData()['domain']; // Temporary flag data 

	// -------------------- STATES --------------------
	// const [skipQuery, setSkipQuery] = useState(false);
	const [userInputRequired, setUserInputRequired] = useState(false);
	const [titleValue, setTitleValue] = useState(props.getWebsiteTempData()['title'] ? props.getWebsiteTempData()['title']:'');
	const [descriptionValue, setDescriptionValue] = useState(props.getWebsiteTempData()['description'] ? props.getWebsiteTempData()['description']:'');
	const enableAPI = (domain === tempFlag) ? false : true;

	// -------------------- REFS --------------------
	const titleMinCountLabel = useRef<HTMLLabelElement>(null);
	const descMinCountLabel = useRef<HTMLLabelElement>(null);
	const errorAlertRef = useRef<any>(null);

	// -------------------- EFFECTS --------------------
	const {data, isLoading, error, refetch} = useQuery(titleDescQuery(protocol, domain, enableAPI));

	// So that it only makes the query once.
	// if (!skipQuery) {
	// 	refetch().then(() => {
	// 	});
	// }

	useEffect(() => {

		if (data && enableAPI) {
			let titleDescResult: TitleDescResult = (data as any)['data'];
			if (
				(titleDescResult.title && titleDescResult.description) &&
				(titleDescResult.title.length >= titleMinCharCount) &&
				(titleDescResult.description.length >= descMinCharCount)
			) {
				// Save details and proceed to next stage if both values are correct
				props.updateWebsiteData('title', titleDescResult.title);
				props.updateWebsiteData('description', titleDescResult.description);
				props.updateWebsiteData('language', titleDescResult.language);

				// Temporary Data
				props.updateWebsiteTempData('domain', domain);
				props.updateWebsiteTempData('title', '');
				props.updateWebsiteTempData('description', '');
				props.nextStage();
			} else {
				// Could not find required data
				setTitleValue(titleDescResult.title);
				setDescriptionValue(titleDescResult.description);

				// Temporary Data
				props.updateWebsiteTempData('domain', domain);
				props.updateWebsiteTempData('title', titleDescResult.title);
				props.updateWebsiteTempData('description', titleDescResult.description);
				setUserInputRequired(true);
			}

			// setSkipQuery(true);
		}
		else if(titleValue && descriptionValue){
			setTitleValue(titleValue);
			setDescriptionValue(descriptionValue);
			setUserInputRequired(true);
		}
		else if(!enableAPI && !titleValue && !descriptionValue){
			props.nextStage();
		}
		
	}, [data]);

	// useEffect(() => {
	// 	setSkipQuery(true);
	// }, [error])

	function buttonClickHandler() {
		// Remove previous error messages
		titleMinCountLabel.current?.classList.remove('show');
		descMinCountLabel.current?.classList.remove('show');
		errorAlertRef.current.close();

		// Check text length
		if (titleValue.length < titleMinCharCount) {
			errorAlertRef.current.show("Please fill in both title & description fields correctly. " +
				"We need it to generate appropriate content for your site.")
			titleMinCountLabel.current?.classList.add('show');
		} else if (descriptionValue.length < descMinCharCount) {
			errorAlertRef.current.show("Please fill in both title & description fields correctly. " +
				"We need it to generate appropriate content for your site.")
			descMinCountLabel.current?.classList.add('show');
		} else {
			// Save details and proceed to next stage
			props.updateWebsiteData('title', titleValue);
			props.updateWebsiteData('description', descriptionValue);
			props.updateWebsiteData('language', 'en');

			// Temporary Data
			props.updateWebsiteTempData('title', titleValue);
			props.updateWebsiteTempData('description', descriptionValue);
			props.nextStage();
		}
	}
	
	function handleInputChange(value: string){
		let title = value.replaceAll(new RegExp(/[^\w\s]/, 'g'), "");
		setTitleValue(title)
		props.updateWebsiteTempData('title', title);
	}

	if (isLoading && enableAPI) {
		return <ConnectWebsiteLoading text={"Processing. Please wait..."}/>

	} else if (error && enableAPI) {
		props.setStage(0);
		return <p>Oops! Something went wrong :(</p>

	} else {

		if (userInputRequired || !enableAPI) {
			return (
				<>
					<p className="connectwebsite-title">Looks like we'll need some more info on <b>{domain}</b></p>
					<div className="connectwebsite-domain-form">
						<div className="field">
							<label className="label">What is your website about?(In one line)&nbsp;<span className="has-text-danger">*</span></label>
							<div className="control">
								<input type={"text"}
											 className="input"
											 value={titleValue}
											 onChange={(e) => handleInputChange(e.target.value)}
											 placeholder={"Title of your website"}/>
							</div>
							<label className={`input-min-counter-label`}
										 ref={titleMinCountLabel}>
								Min. {titleMinCharCount} characters required.
							</label>
						</div>
						<div className="field">
							<label className="label">Tell us more about your Website/Product/Service in brief&nbsp;<span className="has-text-danger">*</span></label>
							<div className="control">
								<textarea className="textarea" value={descriptionValue}
													onChange={(e) => {setDescriptionValue(e.target.value)
														props.updateWebsiteTempData('description', e.target.value);
													}}></textarea>
							</div>
							<label className={`input-min-counter-label`}
										 ref={descMinCountLabel}>
								Min. {descMinCharCount} characters required.
							</label>
						</div>
						<GenericButton text={"PROCEED"}
													 type={"success"}
													 clickHandler={buttonClickHandler}
													 additionalClassList={['is-block', 'ml-auto', 'mr-auto', 'mt-6']}/>
					</div>
					{/* shows error notification if error message is set to non-empty string using its show() method */}
					<ErrorAlert ref={errorAlertRef}/>
				</>
			)

		} else {
			return <ConnectWebsiteLoading text={"Processing. Please wait..."}/>
		}
	}
}

