import "./ContactUs.scss";
import {useRef, useState} from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import {contactUsMutation} from "../../utils/api";
import {useMutation} from "@tanstack/react-query";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import {useLoaderData} from "react-router-dom";

interface PageData {
	user_email: string
}


export default function ContactUs() {
	// ------------------ PAGE DATA ------------------
	const pageData = useLoaderData() as PageData;

	// ------------------ STATES ------------------
	const [files, setFiles] = useState<FileList | null>(null);
	const [subject, setSubject] = useState("");
	const [messageBody, setMessageBody] = useState("");

	const [subjectEmpty, setSubjectEmpty] = useState(false);
	const [messageBodyEmpty, setMessageBodyEmpty] = useState(false);

	// ------------------ REFS ------------------
	const fileInputRef = useRef<HTMLInputElement>(null);
	const errorAlertRef = useRef<any>(null);
	const successAlertRef = useRef<any>(null);

	// ------------------ MUTATIONS ------------------
	const contactUs = useMutation(contactUsMutation);

	// ===============================================
	// ------------------ MAIN CODE ------------------
	// ===============================================
	function sendMessageHandler() {
		// Clear previous error highlights
		setSubjectEmpty(false);
		setMessageBodyEmpty(false);
		successAlertRef.current.close();
		errorAlertRef.current.close();

		if (!subject) {
			setSubjectEmpty(true);
			return;
		}

		if (!messageBody) {
			setMessageBodyEmpty(true);
			return;
		}

		if (files) {
			Array.from(files).forEach(file => {
				console.log(file);
			});
		}

		console.log(subject);
		console.log(messageBody);

		contactUs.mutate({subject: subject, messageBody: messageBody, files: files}, {
			onSuccess: () => {
				// Reset contact form
				setSubject("");
				setMessageBody("");
				if (fileInputRef.current) {
					fileInputRef.current.value = ""
				}
				setFiles(null);
				// Show success message
				successAlertRef.current.show("Your message has been sent successfully!");
			},
			onError: () => {
				// Show error message
				errorAlertRef.current.show("Something went wrong while trying to deliver your message. " +
					"Please try again in some time or contact us through our live chat.");
			}
		})
	}

	return (
		<>
			<div className={"contact-us-container"}>
				<h2 className={"is-size-2 has-text-centered"}>Have Any Queries?</h2>
				<p className={"is-size-4 has-text-centered"}>Send us a message and we will try and reply back to you ASAP!</p>
				<div className={"card mt-6"}>
					<div className={"card-content"}>
						<div className={"content"}>
							<div className={"contact-us-form"}>
								<label className={"label"}>
									From:
									<input type={"text"}
												 defaultValue={pageData.user_email}
												 readOnly={true}
												 className={"input"}/>
								</label>
								<label className={"label mt-4"}>
									<span className={"has-text-danger"}>*</span>&nbsp;Subject/Title:
									<input type={"text"}
												 value={subject}
												 className={`input ${subjectEmpty ? "is-danger" : ""}`}
												 onChange={e => {
													 setSubject(e.target.value)
												 }}/>
								</label>
								<label className={"label mt-4"}>
									<span className={"has-text-danger"}>*</span>&nbsp;Message Body:
									<textarea rows={10}
														className={`textarea ${messageBodyEmpty ? "is-danger" : ""}`}
														value={messageBody}
														placeholder={"Let us know what you think..."}
														onChange={e => {
															setMessageBody(e.target.value)
														}}></textarea>
								</label>
								<label className={"label mt-4"}>
									Add Attachments:
								</label>
								<input type="file"
											 accept={"image/*, .pdf"}
											 multiple={true}
											 ref={fileInputRef}
											 className={"is-block"}
											 onChange={e => {
												 setFiles(e.target.files);
											 }}/>
								{files && <span className={"clear-file-input mt-2"} onClick={() => {
									if (fileInputRef.current) {
										fileInputRef.current.value = "";
										setFiles(null);
									}
								}}>
								X Remove all attachments
							</span>}
								<GenericButton text={contactUs.isLoading ? "Sending Message..." : "Send Message"}
															 additionalClassList={["mt-4", "has-text-weight-bold"]}
															 type={"success"}
															 disable={contactUs.isLoading}
															 clickHandler={sendMessageHandler}/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ErrorAlert ref={errorAlertRef}/>
			<SuccessAlert ref={successAlertRef}/>
		</>
	)
}
