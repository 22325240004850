import{MutableRefObject, useEffect, useState, useMemo} from "react";
import AbunModal from "../../components/AbunModal/AbunModal";
import AbunButton from "../../components/AbunButton/AbunButton";
import Icon from "../../components/Icon/Icon";
import TextArea from "../../components/TextArea/TextArea";
import AbunTable, {IndeterminateCheckbox} from "../../components/AbunTable/AbunTable";
import {ColumnDef, createColumnHelper, RowData, RowModel} from "@tanstack/react-table";
import Card from "../../components/Card/Card";
import {useMutation, useQuery} from "@tanstack/react-query";
import {addCompetitors, getCompetitorDomains, removeCompetitors, retryFn} from "../../utils/api";
import { getDefaultCompetitorLogo } from "../../utils/misc";
import LoadingData from "../../components/LoadingData/LoadingData";
import LoadingError from "../../components/LoadingError/LoadingError";

interface CompetitorSettingsProps {
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	domain: string
}

interface TableRow {
	logo: string
	domain: string
}

export default function Competitors(props: CompetitorSettingsProps) {
	// ---------------------------- NON STATE CONSTANTS ----------------------------
	const domain: string = props.domain;
	const tablePageSizes = [30, 100, 500]

	// ---------------------------- STATES ----------------------------
	const [
		tableData,
		setTableData
	] = useState<Array<TableRow>>();
	const [
		editCompetitorsModal,
		setEditCompetitorsModal
	] = useState(false);
	const [
		domainTextArea,
		setDomainTextArea
	] = useState("");
	const [
		saveCompetitorsEnabled,
		setSaveCompetitorsEnabled
	] = useState(false);
	const [
		selectedRows,
		setSelectedRows
	] = useState<RowModel<RowData>>();

	// ---------------------------- QUERIES ----------------------------
	const {data, isFetching, error, refetch} = useQuery({
		queryKey: ['getCompetitorDomain'],
		queryFn: getCompetitorDomains,
		cacheTime: 0,
		refetchOnWindowFocus: false,
		retry: retryFn,
	});

	// ---------------------------- MUTATIONS ----------------------------
	const removeCompetitorsMut = useMutation({
		mutationKey: ['removeCompetitors'],
		mutationFn: removeCompetitors,
		cacheTime: 0,
		retry: retryFn,
		onError: (error) => {
			console.error(error);
			props.successAlertRef.current?.close();
			props.errorAlertRef.current?.show("Server Error. Please try again in some time.");
		}
	});

	const addCompetitorsMut = useMutation({
		mutationKey: ['addCompetitors'],
		mutationFn: addCompetitors,
		cacheTime: 0,
		retry: retryFn,
		onError: (error) => {
			console.error(error);
			props.successAlertRef.current?.close();
			props.errorAlertRef.current?.show("Server Error. Please try again in some time.");
		}
	})

	// ---------------------------- EFFECTS ----------------------------
	useEffect(() => {
		if (data) {
			let domains: string[] = data['data'];
			setTableData(domains.map(compDomain => {
				return {
					logo: process.env['REACT_APP_LOGO_URL'] + "/" + compDomain,
					domain: compDomain,
				}
			}));
		}
	}, [data]);

	useEffect(() => {
		if (domainTextArea) {
			setSaveCompetitorsEnabled(true);
		} else {
			setSaveCompetitorsEnabled(false);
		}
	}, [domainTextArea]);

	// ---------------------------- FUNCTIONS ----------------------------
	function addCompetitorsHandler() {
		let domains: Array<string> = domainTextArea.split("\n");
		if (domains.length > 0) {
			domains = domains.map(value => value.toLowerCase().trim());
			addCompetitorsMut.mutate({domains: domains}, {
				onSuccess: () => {
					// Close modal
					setEditCompetitorsModal(false);
					setDomainTextArea("");

					// Modify (table) data
					refetch().then();

					props.errorAlertRef.current?.close();
					props.successAlertRef.current?.show("Selected competitor(s) have been removed successfully!");
				}
			})
		}
	}

	function removeCompetitorsHandler(domains: Array<string>) {
		removeCompetitorsMut.mutate({domains: domains}, {
			onSuccess: () => {
				// Uncheck checkboxes
				selectedRows?.rows.forEach(row => {
					row.toggleSelected()
				});

				// Modify (table) data
				refetch().then();

				props.errorAlertRef.current?.close();
				props.successAlertRef.current?.show("Selected competitor(s) have been removed successfully!");
			}
		});
	}

	function selectedRowsSetter(rowModel: RowModel<RowData>) {
		setSelectedRows(rowModel);
	}

	// ---------------------------- TABLE COLUMN DEFINITIONS ----------------------------
	const columnHelper = createColumnHelper<TableRow>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.display({
			id: 'checkbox',
			header: ({table}) => (
				<IndeterminateCheckbox
					{...{
						checked: table.getIsAllRowsSelected(),
						indeterminate: table.getIsSomeRowsSelected(),
						onChange: table.getToggleAllRowsSelectedHandler(),
					}}
				/>
			),
			cell: ({row}) => (
				<IndeterminateCheckbox
					{...{
						checked: row.getIsSelected(),
						disabled: !row.getCanSelect(),
						indeterminate: row.getIsSomeSelected(),
						onChange: row.getToggleSelectedHandler(),
					}}
					name={"competitorSelection"}
					value={row.original.domain}
				/>
			),
			enableGlobalFilter: true,
		}),
		columnHelper.accessor((row: TableRow) => row.logo, {
			id: 'logo',
			header: "Logo",
			cell: useMemo(() => cellData => {
				return <div className={"competitors-table-logo-container"}>
					<img src={cellData.getValue()} alt="logo" className={"competitors-table-logo"}
					onError={({currentTarget}) => {
					currentTarget.onerror = null;
					currentTarget.src = getDefaultCompetitorLogo();
					}}/>
				</div>
			},[]),
			enableGlobalFilter: false,
		}),
		columnHelper.accessor((row: TableRow) => row.domain, {
			id: 'domain',
			header: "Domain",
			cell: cellData => cellData.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'removeDomain',
			header: "Action",
			cell: props => (
				<AbunButton type={"danger"} className={"is-small is-outlined"} clickHandler={() => {
					removeCompetitorsHandler([props.row.original.domain]);
				}}>
					Remove Competitor
				</AbunButton>
			),
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
	]

	// ==========================================================================
	// ---------------------------- MAIN RENDER CODE ----------------------------
	// ==========================================================================

	if (isFetching) return <LoadingData/>;

	if (error) return <LoadingError/>;

	return (
		<>
			<AbunModal active={editCompetitorsModal}
								 headerText={""}
								 closeable={true}
								 hideModal={() => setEditCompetitorsModal(false)}>
				<h4 className={"is-size-4 mt-2 has-text-centered font-secondary has-text-primary has-text-weight-bold"}>
					Enter Competitors
				</h4>
				<p className={"has-text-centered"}>
					You can add both full URLs (ex: <b>https://example.com/blog/</b>) or only registered
					domain (ex: <b>example.com</b>). Invalid values will be ignored.
				</p>
				<TextArea value={domainTextArea}
									className={"mt-4"}
									placeholder={"Enter each competitor on a new line..."}
									rows={15}
									onChange={setDomainTextArea}/>
				<AbunButton type={"success"}
										className={"mt-5 is-block ml-auto mr-auto"}
										disabled={!saveCompetitorsEnabled || addCompetitorsMut.isLoading}
										clickHandler={addCompetitorsHandler}>
					<Icon iconName={"floppy-disk"} additionalClasses={["icon-white"]}/>
					&nbsp;&nbsp;{addCompetitorsMut.isLoading ? "Saving..." : "Save Competitors"}
				</AbunButton>
			</AbunModal>

			<div className={"is-flex is-justify-content-space-between is-align-items-center mt-4"}>
				<h2 className={"is-size-4 has-text-primary font-secondary"}>Your Current Competitors:</h2>
				<div>
					<AbunButton type={"success"}
											className={"is-small has-text-weight-bold"}
											clickHandler={() => setEditCompetitorsModal(true)}>
						<Icon iconName={"plus"} additionalClasses={["icon-white"]}/>
						<p className={"btn-txt ml-2"}>Add Competitors</p>
					</AbunButton>
					<AbunButton type={"danger"}
											className={"ml-4 is-small has-text-weight-bold"}
											clickHandler={() => {
												// Fetch the row domain values and call delete function.
												let domains = selectedRows?.rows.map(row => {
													return (row.original as TableRow).domain;
												});
												if (domains) {
													removeCompetitorsHandler(domains);
													setSelectedRows(undefined);
												}
											}}>
						<Icon iconName={"delete"} additionalClasses={["icon-white"]}/>
						<p className={"btn-txt ml-2"}>Remove Selected</p>
					</AbunButton>
				</div>
			</div>
			<hr className={"divider"}/>
			<Card className={"mt-4"}>
				<AbunTable tableContentName={"Competitors"}
									 tableData={tableData || []}
									 columnDefs={columnDefs}
									 pageSizes={tablePageSizes}
									 initialPageSize={tablePageSizes[0]}
									 noDataText={`No competitors have been added for ${domain}`}
									 searchboxPlaceholderText={"Search competitors..."}
									 selectedRowsSetter={selectedRowsSetter}
									 rowCheckbox={true}/>
			</Card>
		</>
	)

}
